export interface SubscriptionType {
	key: string;
	label: string;
}

export const SUBSCRIPTION_TYPES: SubscriptionType[] =
[

	{
		key: 'trial',
		label: 'Trial',
	},

	{
		key: 'internal',
		label: 'Internal',
	},

	{
		'key': 'paying:lite',
		label: 'Lite',
	},

	{
		key: 'paying:essential',
		label: 'Essential',
	}

];
