import {defineStore} from "pinia";
import {ErrorMessage} from "@/stores/errors/ErrorMessage";
import {Organisation} from "@/models/Organisation.model";
import {api} from "@/utils/Api.util";
import {PaginationState} from "@/models/PaginationState.model";
import {ApiErrors} from "@/stores/errors/ApiErrors";
import { OrderParameter } from "@/models/OrderParameter.model";
import { useDefaultOrderStore } from "@/stores/DefaultOrder.store";
import { useGoalsStore } from "./Goals.store";

interface OrganisationStoreState {
	errorMessage: ErrorMessage | null,
	currentOrganisation: Organisation | null,
	organisations: Organisation[],
	currentPage: PaginationState | null,
	currentFilters: OrganisationFilter | null,
	order: OrderParameter
}

export class OrganisationFilter {
	searchQuery: string | null = null;
	subscriptionPlan: string | null = null;
	country: string | null = null;
	expired: boolean | null = null;
}

export const useOrganisationsStore = defineStore('organisations', {

	state: (): OrganisationStoreState => ({
		errorMessage: null,
		currentOrganisation: null,
		organisations: [],
		currentPage: null,
		currentFilters: null,
		order: useDefaultOrderStore().organisationsOrder
	}),

	actions: {

		new() {
			let newOrg = new Organisation();
			newOrg.locale = 'nl-BE';
			newOrg.subscription_plan = 'trial';
			newOrg.agenda_settings = {
				include_weekends: false,
				school_hours_start: null,
				school_hours_end: null
			}
			return newOrg;
		},

		async load(page: PaginationState | null = null, filters: OrganisationFilter) {

			const params: any = {
				mask: [
					'*',
					'owner.*',
					'agenda_settings.*',
				].join(','),
				page: page ? page.currentPage : null,
			};

			if (this.order) {
				params.order = this.order.getApiProperty();
			}

			if (filters.searchQuery) {
				params.query = filters.searchQuery;
			}

			if (filters.subscriptionPlan) {
				params.subscription_plan = filters.subscriptionPlan;
			}

			if (filters.country) {
				params.country = filters.country;
			}

			if (filters.expired !== null) {
				params.expired = filters.expired ? 1 : 0;
			}

			this.organisations = [];
			const response = await api.get('organisations', {
				params: params
			});

			this.organisations = response.data.data.map(Organisation.mapFromServer);
			this.currentPage = PaginationState.mapFromServer(response.data.meta);
			this.currentFilters = filters;

		},

		changeOrder(order: OrderParameter) {
			this.order = order;
			this.load(this.currentPage, this.currentFilters, order);

			useDefaultOrderStore().setDefaultOrganisationsOrder(order);
		},

		async nextPage() {
			return await this.load(this.currentPage.getNext());
		},

		async previousPage() {
			return await this.load(this.currentPage.getPrevious());
		},

		async loadCurrentOrganisation() {

			const response = await api.get('organisation', {
				params: {
					mask: '*,features.*'
				}
			});

			this.setCurrentOrganisation(Organisation.mapFromServer(response.data.data));

		},

		setCurrentOrganisation(organisation: Organisation) {
			this.currentOrganisation = organisation;
			useGoalsStore().curriculum = organisation.curriculum;
		},

		clearErrorMessage() {
			this.errorMessage = null;
		},

		switchToOrganisation(organisation: Organisation) {
			localStorage.setItem('organisation_id', organisation.id);
			window.location.href = '/';
		},

		async save(organisation: Organisation) {
			this.clearErrorMessage();
			try {
				const response = await api.put(
					'organisations/' + organisation.id,
					organisation.getServerData()
				);
				this.updateStoreItem(organisation);
			} catch (e) {
				this.errorMessage = ApiErrors.fromAxiosException(e);
				throw this.errorMessage;
			}
		},

		async create(organisation: Organisation, email: string, sendInvitation: boolean) {
			this.clearErrorMessage();
			try {
				const response = await api.post(
					'organisations',
					{
						...organisation.getServerData(),
						email: email,
						send_invitation: sendInvitation
					},
					{
						params: {
							mask: [
								'*',
								'owner.*'
							].join(','),
						}
					}
				);
				organisation.setFromServerData(response.data.data);
				this.addStoreItem(organisation);
			} catch (e) {
				this.errorMessage = ApiErrors.fromAxiosException(e);
				throw this.errorMessage;
			}
		},

		updateStoreItem(organisation: Organisation) {
			let storeIndex = this.organisations.findIndex(
				storeItem => storeItem.id == organisation.id
			);

			if (storeIndex > -1) {
				this.organisations[storeIndex] = organisation.clone();
			}
		},

		addStoreItem(organisation: Organisation) {
			this.organisations.push(organisation);
		},
	}

});
