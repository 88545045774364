import { defineStore } from 'pinia';
import { Lesson } from '@/models/Lesson.model';
import { Pupil } from '@/models/Pupil.model';
import { LessonItem } from '@/models/LessonItem.model';
import { orgApi } from "@/utils/Api.util";
import { PupilActivity } from "@/models/PupilActivity.model";
import { usePupilsStore } from "@/stores/Pupils.store";
import { ApiErrors } from "@/stores/errors/ApiErrors";
import md5 from "md5";
import { useClassroomsStore } from "@/stores/Classrooms.store";
import { DateTime } from "luxon";
import * as Sentry from "@sentry/vue";

export const MAX_LESSON_END_POSTPONING = 60 * 15;
export const MAX_LESSON_START_PREPONING = 60 * 30;

export const useActivityBoardStore = defineStore('activityBoard', {

	state: () => ({
		avatarWithSymbol: 			true as boolean,
		avatarWithName: 			false as boolean,
		avatarColorFilterEnabled: 	false as boolean,
		avatarSelectedColors: 		Pupil.COLORS,
		relatedLesson: 				null as Lesson | null,
		boardItems: 				[] as any[],
		timedAutoCloseDown:         null as any,
		outOfExtraTime:             false as boolean,
	}),

	getters: {
		activitiesPaused(state) {
			return state.relatedLesson?.paused;
		},
	},

	actions: {

		/**
		 * @param time
		 */
		async clear(time: DateTime) {
			const response = await orgApi.post('pupil-activities/' + this.relatedLesson.id + '/close', {
				checkout: time.toJSDate()
			});
			this.closeBoard();
		},

		/**
		 * @param data
		 */
		handleClosedOnOtherClient(data) {
			this.closeBoard();
		},

		handleActivityBoardChanged(data) {
			this.load();
		},

		handleActivityBoardRemoved(data) {
			this.closeBoard();
		},

		/**
		 *
		 */
		closeBoard() {
			this.relatedLesson = null;
			window.localStorage.removeItem('activity_board_lesson');
		},

		/**
		 * @param avatarWithSymbol
		 * @param avatarWithName
		 */
		setAvatarLook(avatarWithSymbol: Boolean, avatarWithName: Boolean) {
			this.avatarWithSymbol = avatarWithSymbol;
			this.avatarWithName = avatarWithName;
		},

		/**
		 * @param pauseValue
		 */
		async setActivitiesPause(pauseValue: Boolean) {
			if (pauseValue) {
				const response = await orgApi.post('pupil-activities/' + this.relatedLesson.id + '/pause', {});
			} else {
				const response = await orgApi.post('pupil-activities/' + this.relatedLesson.id + '/resume', {});
			}

			this.relatedLesson.paused = pauseValue;
		},

		/**
		 * @param enable
		 */
		enableAvatarColorFilter(enable: Boolean) {
			this.avatarColorFilterEnabled = enable;
		},

		/**
		 * @param colors
		 */
		setAvatarSelectedColors(colors: string[]) {
			this.avatarSelectedColors = colors;
		},

		/**
		 * @param pupil
		 */
		getBoardItemForCheckedInPupil(pupil: Pupil) {
			return this.boardItems.find(bi => bi.checkins.map(p => p.pupil.id).includes(pupil.id));
		},

		/**
		 * @param checkin
		 */
		getBoardItemForCheckin(checkin: PupilActivity) {
			return this.boardItems.find(bi => bi.checkins.map(p => p.id).includes(checkin.id));
		},

		/**
		 * @param pupil
		 */
		getLessonItemForCheckedInPupil(pupil: Pupil) {
			const boardItem = this.getBoardItemForCheckedInPupil(pupil);
			if(boardItem) {
				return boardItem.lessonItem;
			}
			return null;
		},

		/**
		 * @param pupil
		 */
		isCheckedIn(pupil: Pupil) {
			return this.getBoardItemForCheckedInPupil(pupil) !== undefined;
		},

		/**
		 * @param pupil
		 * @param lessonItem
		 */
		async checkIn(pupil: Pupil, lessonItem: LessonItem) {
			const boardItem = this.boardItems.find(bi => bi.lessonItem.id === lessonItem.id);

			try {
				const response = await orgApi.post('pupil-activities', {
					pupil: {
						id: pupil.id
					},
					lessonItem: {
						id: lessonItem.id
					},
					checkin: new Date()
				}, {
					params: {
						mask: '*,lesson.activity_board_hash'
					}
				});

				// If successful API call...
				const checkin = PupilActivity.mapFromServer(response.data.data);
				checkin.pupil = pupil;

				this.addCheckin(boardItem, checkin);

				// Check mask
				if (response.data.data.lesson.activity_board_hash) {
					await this.checkLessonHash(response.data.data.lesson.activity_board_hash);
				}

				return checkin;

			} catch (e) {
				const error = ApiErrors.fromAxiosException(e);
				if (error.response.status === 409) {
					// No more slots left, reload the board, but do not wait for that
					this.loadBoardItems();
				}

				throw error;
			}
		},

		/**
		 * @param data
		 */
		handleCheckInOnOtherClient(data) {

			// Not loaded? Ignore.
			if (!this.relatedLesson) {
				return;
			}

			const lessonItemId = data.lessonItemId;

			const boardItem = this.boardItems.find(bi => bi.lessonItem.id == lessonItemId);
			if (!boardItem) {
				return;
			}

			const pupil = usePupilsStore().findById(data.pupilId);
			if (!pupil) {
				return;
			}

			const checkin = PupilActivity.mapFromServer(data.pupilActivity);
			checkin.pupil = pupil;

			this.addCheckin(boardItem, checkin);
			this.checkLessonHash(data.hash);
		},

		/**
		 * @param boardItem
		 * @param checkin
		 */
		addCheckin(boardItem: any, checkin: PupilActivity) {
			if (boardItem.checkins.findIndex(ci => ci.id === checkin.id) < 0) {
				boardItem.checkins.push(checkin);
			}
		},

		/**
		 * @param boardItem
		 * @param checkin
		 */
		removeCheckin(boardItem: any, checkin: PupilActivity) {
			boardItem.checkins = boardItem.checkins.filter(p => p.id !== checkin.id);
		},

		/**
		 * @param newHash
		 */
		async checkLessonHash(newHash) {
			if (this.calculateHash() !== newHash) {
				Sentry.captureMessage("Activity board: hash mismatch, reloading...");
				await this.load();
			}
		},

		/**
		 *
		 */
		calculateHash() {
			const out = this.boardItems.map(bi => {
				return {
					lessonItem: '' + bi.lessonItem.id,
					pupils: bi.checkins.map(p => '' + p.pupil.id).sort((a, b) => parseInt(a) - parseInt(b)),
				}
			});

			out.sort((a, b) => a.lessonItem - b.lessonItem);
			return md5(JSON.stringify(out));
		},

		/**
		 * @param checkin
		 * @param feedbackScore
		 */
		async checkOut(checkin: PupilActivity, feedbackScore: number) {
			const boardItem = this.getBoardItemForCheckin(checkin);

			if(!boardItem) {
				return false;
			}

			const response = await orgApi.put('pupil-activities/' + checkin.id, {
				feedback: feedbackScore,
				checkout: new Date()
			});

			// If successful API call...
			this.removeCheckin(boardItem, checkin);
			return true;
		},

		/**
		 * @param data
		 */
		handleCheckOutOnOtherClient(data) {

			// Not loaded? Ignore.
			if (!this.relatedLesson) {
				return;
			}

			const lessonItemId = data.lessonItemId;

			const boardItem = this.boardItems.find(bi => bi.lessonItem.id == lessonItemId);
			if (!boardItem) {
				return;
			}

			const checkinId = data.pupilActivity.id;
			if (checkinId) {
				boardItem.checkins = boardItem.checkins.filter(p => p.id !== checkinId);
			}

			this.checkLessonHash(data.hash);
		},

		/**
		 * @param data
		 */
		handlePauseOnOtherClient(data) {

			// Not loaded? Ignore.
			if (!this.relatedLesson) {
				return;
			}

			this.relatedLesson.paused = data.paused;
			this.checkLessonHash(data.hash);
		},

		/**
		 * @param lesson
		 * @param isOpenAction
		 */
		async loadLesson(lesson: Lesson, isOpenAction = false) {

			let path = 'lessons/' + lesson.id;
			if (isOpenAction) {
				path += '/open';
			}

			const response = await orgApi.get(path, {
				params: {
					mask: [
						'id',
						'type',
						'title',
						'description',
						'start',
						'end',
						'color',
						'classroom',
						'seats',
						'lessonItems.*',
						'lessonItems.activity.*',
						'lessonItems.icon.*',
						'lessonItems.icon.library.id',
						'paused',
						'broadcast_channel'
					].join(',')
				}
			});
			return Lesson.mapFromServer(response.data.data);

		},

		/**
		 * @param lesson
		 * @param isConnectLoad
		 */
		async load(lesson: Lesson | null, isConnectLoad = false) {

			if(!lesson && !this.relatedLesson) {
				return Promise.reject(new Error('No lesson specified'));
			}

			// Always reload lesson to get latest pause state
			if (lesson) {
				this.relatedLesson = await this.loadLesson(lesson, isConnectLoad);
			} else {
				this.relatedLesson = await this.loadLesson(this.relatedLesson);
			}

			if(this.timedAutoCloseDown) {
				clearTimeout(this.timedAutoCloseDown);
			}

			// Set timeout to close down the board after the lesson (+ extra time) has ended
			this.outOfExtraTime = false;
			this.timedAutoCloseDown = setTimeout(
				() => {
					this.outOfExtraTime = true;
				},
				Math.max(0, (this.relatedLesson.end.getTime() - new Date().getTime()) + (MAX_LESSON_END_POSTPONING * 1000))
			);

			// Make sure pupils are loaded
			const pupilsStore = usePupilsStore();
			const classroomsStore = useClassroomsStore();
			classroomsStore.setCurrentClassroom(this.relatedLesson.classroom);

			// Always do force reload to allow for real time updates
			await pupilsStore.load(true, true);

			// Assign already checked in pupils (= pupilActivities) to the lessonItems
			await this.loadBoardItems();
		},

		/**
		 *
		 */
		async loadBoardItems() {

			const pupilActivities = await this.loadPupilActiveActivities();

			const boardItems = [];
			this.relatedLesson.lessonItems.forEach(lessonItem => {
				const checkins = pupilActivities.filter(pa => pa.lessonItem && pa.lessonItem.id === lessonItem.id);
				boardItems.push({
					lessonItem: lessonItem,
					checkins: checkins
				});
			});

			this.boardItems = boardItems;
			return;

		},

		/**
		 *
		 */
		async loadPupilActiveActivities() {

			const response = await orgApi.get('pupil-activities', {
				params: {
					lessons: this.relatedLesson.id,
					active: 1,
					mask: [
						'id',
						'checkin',
						'checkout',
						'pupil.id',
						'lessonItem.id'
					].join(','),
				}
			});

			// don't use line below, the mapping function being used here has a 2nd argument which the map() function fills with the array index
			// const pupilActivities = response.data.data.map(PupilActivity.mapFromServer);
			const pupilActivities = response.data.data.map(obj => (new PupilActivity()).setFromServerData(obj) );

			// Replace pupil ids with the objects from the pupilsStore
			const pupilsStore = usePupilsStore();

			pupilActivities.forEach(pa => {
				const pupil = pupilsStore.findById(pa.pupil.id);
				if (!pupil) {
					//throw new Error('Pupil not found: ' + pa.pupil.id);
					// Pupil may be removed from the classroom. In case it is not found, just ignore it.
					pa.pupil = null;
				}
				pa.pupil = pupil;
			});

			return pupilActivities.filter(pa => pa.pupil); // Only include pupils that could be found
		}
	},
});
