import {defineStore} from 'pinia'
import {Activity} from "@/models/Activity.model";
import {orgApi} from "@/utils/Api.util";
import { Icon } from "@/models/Icon.model";
import { Symbol } from "@/models/Symbol.model";
import {ApiErrors} from "@/stores/errors/ApiErrors";
import {ErrorMessage} from "@/stores/errors/ErrorMessage";
import {useClassroomsStore} from "@/stores/Classrooms.store";

const activityMask: string[] = [
	'id',
	'name',
	'seats',
	'color',
	'symbol',
	'icon.*',
	'icon.library.id'
];

interface ActivityStoreState {
	errorMessage: ErrorMessage | null,
	activities: Activity[],
	activitiesMap: { [id: string]: Activity[] },
	loadedClassroomId: string | null,
	activityNamesForAllMyClassrooms: string[],
	symbolsInUseMap: { [url: string]: Activity },
	iconsInUseMap: { [id: number]: Activity },
}

export const useActivitiesStore = defineStore('activities', {

	state: (): ActivityStoreState => ({
		errorMessage: null,
		activities: [],
		activitiesMap: {},
		loadedClassroomId: null,
		activityNamesForAllMyClassrooms: [],
		symbolsInUseMap: {},
		iconsInUseMap: {},
	}),

	getters: {
		isIconUsed: (state) => {
			return (icon: Icon | Symbol) => {
				if(icon instanceof Symbol && icon.url && typeof (state.symbolsInUseMap[icon.url]) !== 'undefined') {
					return true;
				} else if (icon instanceof Icon && icon.id && typeof (state.iconsInUseMap[icon.id]) !== 'undefined') {
					return true;
				} else {
					return false;
				}
			}
		},
	},

	actions: {

		new() {
			let newActivity = new Activity();
			newActivity.classroomId = useClassroomsStore().currentClassroom.id.toString();
			newActivity.seats = 2;	// default seats number

			return newActivity;
		},

		async load() {

			const classroomId = useClassroomsStore().currentClassroom.id;

			// Only load when not already loaded
			if (this.activities.length > 0 && this.loadedClassroomId === classroomId) {
				return;
			}

			let params: any = {};

			params.classroomIds = useClassroomsStore().currentClassroom.id;
			params.mask = activityMask.join(',');

			this.loadedClassroomId = classroomId;
			const response = await orgApi.get('activities', {
				params: params
			});

			this.activities = response.data.data.map(Activity.mapFromServer);
			this.updateMap();

			this.sortItemsBy('name');

			this.updateAvailableSymbolsIcons();
		},

		async getActivityNames(currentClassroomOnly: boolean = true) {

			if(currentClassroomOnly) {
				await this.load();
				return this.activities.map(item => item.name);
			} else {

				if(this.activityNamesForAllMyClassrooms.length > 0) {
					return this.activityNamesForAllMyClassrooms;
				}

				const classroomIds = useClassroomsStore().classrooms.map(cr => cr.id);

				const response = await orgApi.get('activities', {
					params: {
						classroomIds: classroomIds.join(','),
						mask: 'name'
					}
				});

				this.activityNamesForAllMyClassrooms = response.data.data.map(item => item.name);
				return this.activityNamesForAllMyClassrooms;
			}
		},

		findById(id: string) {
			if (this.activitiesMap[id]) {
				return this.activitiesMap[id];
			}
			return null;
		},

		findByIds(ids: string[]) {
			return this.activities.filter(p => ids.indexOf(p.id) !== -1)
		},

		async save(activity: Activity) {

			this.errorMessage = null;

			if(activity.id) {
				// update existing pupil
				try {
					const response = await orgApi.put('activities/' + activity.id, activity.getServerData());
					this.updateStoreItem(activity);
				} catch (e: any) {
					this.errorMessage = ApiErrors.fromAxiosException(e);
					throw this.errorMessage;
				}

			} else {
				// create new pupil
				try {
					const response = await orgApi.post('activities', activity.getServerData());
					activity.id = response.data.data.id;
					this.addStoreItem(activity);
				} catch (e: any) {
					this.errorMessage = ApiErrors.fromAxiosException(e);
					throw this.errorMessage;
				}
			}

			this.sortItemsBy('name');
		},

		async delete(activity: Activity) {

			this.errorMessage = null;
			try {
				const response = await orgApi.delete('activities/' + activity.id);
				this.deleteStoreItem(activity);
			} catch (e: any) {
				this.errorMessage = ApiErrors.fromAxiosException(e);
				throw this.errorMessage;
			}

		},

		updateStoreItem(activity: Activity) {
			const storeIndex = this.activities.findIndex(
				storeItem => storeItem.id == activity.id
			);
			this.activities[storeIndex] = activity.clone();
		},

		addStoreItem(activity: Activity) {
			this.activities.push(activity);
		},

		deleteStoreItem(activity: Activity) {
			const index = this.activities.findIndex(v => v.id === activity.id);
			if (index >= 0) {
				this.activities.splice(index, 1);
			}
		},

		clearErrorMessage() {
			this.errorMessage = null;
		},

		setActivityCustomIcon(activity: Activity, icon: Icon) {
			activity.icon = icon;
			activity.symbol = null;
		},

		setActivityStandardIcon(activity: Activity, symbol: Symbol) {
			activity.symbol = symbol;
			activity.icon = null;
		},

		sortItemsBy(attr) {
			// Sort on name (for now)
			this.activities.sort(
				(a: Activity, b: Activity) => {
					return a[attr].localeCompare(b[attr]);
				}
			);
		},

		getFromId(id: string): Activity | null {
			return this.pupils.find(
				storeItem => storeItem.id == id
			);
		},

		updateAvailableSymbolsIcons() {

			if(!this.loadedClassroomId) {
				return;
			}

			this.symbolsInUseMap = {};
			this.iconsInUseMap = {};

			this.activities.forEach(
				(activity: Activity) => {
					const icon = activity.getIcon();
					if(icon instanceof Symbol) {
						this.symbolsInUseMap[icon.url] = activity;
					} else if(icon instanceof Icon) {
						this.iconsInUseMap[icon.id] = activity;
					}
				}
			);

		},

		updateMap() {
			this.activities.forEach(activity => {
				this.activitiesMap[activity.id] = activity;
			});
		},

	}

});
