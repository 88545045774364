import { Activity } from "./Activity.model";
import { Pupil } from "./Pupil.model";
import { Icon } from "./Icon.model";
import { Symbol } from "./Symbol.model";
import { AbstractHasSymbol } from "@/models/AbstractHasSymbol.model";
import { Lesson } from "@/models/Lesson.model";
import { LessonPlan } from "@/models/LessonPlan.model";

export class LessonItem extends AbstractHasSymbol {

	public static create(activity: Activity = null): LessonItem {
		const item = new LessonItem();

		if (activity) {
			item.activity = activity;
			item.seats = activity.seats;
			item.icon = activity.icon;
			item.symbol = activity.symbol;
		}

		return item;
	}

	public id?: string;
	public allowedPupilIds?: string[] = [];
	public activity?: Activity;
	public seats: number;
	public lesson?: Lesson;
	public lessonPlan?: LessonPlan;
	public guided: boolean = false;

	isPupilSelected(pupil: Pupil): boolean {
		return this.allowedPupilIds && this.allowedPupilIds.indexOf(pupil.id) !== -1;
	}

	setPupilSelected(pupil: Pupil, selected: boolean) {

		if (!this.allowedPupilIds) {
			this.allowedPupilIds = [];
		}

		if (selected) {
			if (this.isPupilSelected(pupil)) {
				return;
			}
			this.allowedPupilIds.push(pupil.id);
		} else {
			const index = this.allowedPupilIds.indexOf(pupil.id);
			if (index !== -1) {
				this.allowedPupilIds.splice(index, 1);
			}
		}

	}

	getDescriptiveTitle(): string {
		if (this.lessonPlan?.title) {
			return this.activity.name + ' / ' + this.lessonPlan?.title;
		} else {
			return this.activity.name;
		}
	}

	static mapFromServer(data: any): LessonItem {
		return (new LessonItem()).setFromServerData(data);
	}

	setFromServerData(data: any) {

		this.id = data.id;
		// this.instructions = data.instructions;
		this.seats = data.seats;
		// this.notes = data.notes;
		this.guided = data.guided;
		// this.title = data.title;

		if (data.activity) {
			this.activity = Activity.mapFromServer(data.activity);
		}

		if (data.icon) {
			this.icon = Icon.mapFromServer(data.icon);
		}

		if (data.symbol) {
			this.symbol = Symbol.mapFromServer(data.symbol);
		}

		if (data.pupils) {
			this.allowedPupilIds = data.pupils.map((pupil: any) => pupil.id);
		}

		if (data.lesson) {
			this.lesson = Lesson.mapFromServer(data.lesson);
		}

		if (data.lessonPlan) {
			this.lessonPlan = LessonPlan.mapFromServer(data.lessonPlan);
		}

		return this;
	}

	getServerData() {
		const out: any = {
			id: this.id,
			seats: this.seats,
			// title: this.title,
			// notes: this.notes,
			// instructions: this.instructions,
			guided: this.guided,
			icon: this.icon ? this.icon.getServerData() : null,
			symbol: this.symbol ? this.symbol.getServerData() : null,
			lessonPlan: this.lessonPlan ? this.lessonPlan.getServerData() : null,
		};

		// allowedPupilIds MAY be NULL, which means that the server will automatically assign all available pupils
		// (or not, but the logic is on the server)
		if (this.allowedPupilIds) {
			out.pupils = this.allowedPupilIds.map((id) => {
				return {
					id
				}
			});
		}

		if (this.activity) {
			out.activity = {
				id: this.activity.id,
			}
		}

		if(this.lessonPlan) {
			if (this.lessonPlan.isSavedInLib) {
				out.lessonPlan = {
					id: this.lessonPlan.id,
					version: this.lessonPlan.version
				};
			} else {
				out.lessonPlan = this.lessonPlan.getServerData();
			}
		}

		return out;
	}

	setFromModel(model: LessonItem) {

		if (model === this) {
			return;
		}

		this.id = model.id;
		this.seats = model.seats;
		this.guided = model.guided;

		if (model.activity) {
			this.activity = model.activity;
		}

		if (model.icon) {
			this.icon = model.icon;
		}

		if (model.symbol) {
			this.symbol = model.symbol;
		}

		this.allowedPupilIds = [ ... model.allowedPupilIds ];

		this.lessonPlan = null;
		if (model.lessonPlan) {
			this.lessonPlan = model.lessonPlan.clone();
		}

		return this;
	}

	clone(): LessonItem {
		return (new LessonItem()).setFromModel(this);
	}
}
