import { nextTick } from 'vue';
import { createI18n } from 'vue-i18n';
import {api, orgApi} from "@/utils/Api.util";
import { Settings as LuxonSettings } from "luxon";

// Locales for which we have translation files
export const SUPPORTED_LOCALES = {
	'en-GB': 'English (UK)',
	'nl-BE': 'Nederlands (BE)',
	// 'nl-NL': 'Nederlands (NL)',
	'es-ES': 'Español (ES)',
	'fr-FR': 'Français (FR)',
	'de-DE': 'Deutsch (DE)',
};

// Countries that can be selected
// Warning: we use the 2-letter ISO country code as key, not the 3-letter code.
// 3-letter code will not be accepted by the API.
export const SUPPORTED_COUNTRIES = {
	'BE': 'Belgium',
	'BG': 'Bulgaria',
	'GR': 'Greece',
	'NL': 'Netherlands',
	'PL': 'Poland',
	'ES': 'Spain',
	'UK': 'United Kingdom',
};

export const DEFAULT_LOCALE = 'en-GB';

// Locales for which we have translation files, but may not be complete (and thus should fallback to another locale)
const FALLBACK_LOCALES = {
	'nl-NL': 'nl-BE',
}

// Locales that are aliased to another locale (e.g. locale 'nl' provided by browser is aliased to 'nl-BE')
const ALIAS_LOCALES = {
	'nl': 'nl-BE',
	'en': 'en-GB',
	'es': 'es-ES',
	'fr': 'fr-FR',
	'fr-BE': 'fr-FR',
	'de': 'de-DE',
}


export let i18n;

export async function setupI18n(options = {}) {

	let localeOption = options.locale;
	delete options.locale;	// we don't want to pass locale to createI18n as it would add the locale to the list of available locales
							// without downloading the corresponding messages. We'll use a dedicated function to set/download locale.

	// disable warnings
	options.silentTranslationWarn = true;
	options.silentFallbackWarn = true;

	i18n = createI18n(options);
	await setI18nLanguage(i18n, localeOption);

	return i18n;
}

export async function setI18nLanguage(i18n, locale) {

	locale = resolveLocale(locale);

	// (down)load locale messages if necessary
    if (!i18n.global.availableLocales.includes(locale)) {
		await downloadMessages(i18n, locale);
	}

	if (i18n.mode === 'legacy') {
		i18n.global.locale = locale;
	} else {
		i18n.global.locale.value = locale;
	}

	api.defaults.headers.common['X-Locale'] = locale;
	orgApi.defaults.headers.common['X-Locale'] = locale;

	document.querySelector('html').setAttribute('lang', locale);

	LuxonSettings.defaultLocale = locale;

	if(FALLBACK_LOCALES[locale]) {
		await downloadMessages(i18n, FALLBACK_LOCALES[locale]);
		if (i18n.mode === 'legacy') {
			i18n.global.fallbackLocale = FALLBACK_LOCALES[locale];
		} else {
			i18n.global.fallbackLocale.value = FALLBACK_LOCALES[locale];
		}
	}
}

export async function downloadMessages(i18n, locale) {

	if(!validLocale(locale)) {
		throw new Error(`Invalid locale ${locale}`);
	}

	// load locale messages with dynamic import
	// Limitations: https://github.com/rollup/plugins/tree/master/packages/dynamic-import-vars#limitations
	const messages = await import(
		`./i18n/lang_${locale}.json`
	);

	// set locale and locale message
	i18n.global.setLocaleMessage(locale, messages.default);

	return nextTick();
}

function resolveLocale(locale) {

	let resolvedLocale = locale;
	if(ALIAS_LOCALES[locale]) {
		resolvedLocale = ALIAS_LOCALES[locale];
	}

	if(!validLocale(resolvedLocale)) {
		resolvedLocale = DEFAULT_LOCALE;
	}
	return resolvedLocale;
}

function validLocale(locale) {
	if(SUPPORTED_LOCALES[locale]) {
		return true;
	}
	return false;
}
