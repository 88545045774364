<template>
<TheStdLayout>


	<div class="font-sans text-base-content antialiased">
		<div class="fixed inset-0 bg-base-100 grid place-items-center max-h-screen overflow-scroll">

			<div class="w-full sm:max-w-md flex flex-col items-center">
				<img src="/smartsymbols-logo.png" alt="SmartSymbols logo" class="w-60" />

				<div class="bg-white w-full mt-6 px-6 py-4 overflow-hidden sm:rounded-lg mb-10">

					<span class="uppercase text-sm font-bold text-base-content-light px-2">{{ $t('My schools') }}</span>


					<div class="grid divide-y divide-base-200 mt-4 w-full">

						<button class="flex justify-between gap-2 py-4 hover:bg-gray-50 px-2 btn-effect" v-for="role in me.roles" @click="selectOrganisation(role)">
							<span class="text-left font-semibold" :class="!role.organisation.active ? 'text-gray-400' : ''">{{ role.organisation.name }}</span>

							<span v-if="!role.organisation.active">{{ $t('Expired') }}</span>
							<ArrowRightCircleIcon v-else class="w-7 h-7 shrink-0" />
						</button>

						<button class="py-4 hover:bg-gray-50 px-2 btn-effect text-danger text-center" @click="logout">
							{{ $t('Log out') }}
						</button>

					</div>

				</div>
			</div>

		</div>
	</div>


</TheStdLayout>
</template>


<script lang="ts">

import { mapActions, mapState, mapStores } from "pinia";
import { useOrganisationsStore } from "@/stores/Organisations.store";
import {useUsersStore} from "@/stores/Users.store";
import { ArrowRightCircleIcon } from "@heroicons/vue/24/outline";
import { auth } from "@/auth";
import {Organisation} from "@/models/Organisation.model";
import { UserRole } from "@/models/UserRole";

export default {

	data() {
		return {

		};
	},

	components: {
		ArrowRightCircleIcon,
	},

	computed: {
		...mapState(useUsersStore, ['me']),
	},

	mounted() {

	},

	methods: {

		...mapActions(useOrganisationsStore, ['switchToOrganisation']),

		selectOrganisation(role: UserRole) {
			if (!role.organisation.active) {
				return;
			}

			this.switchToOrganisation(role.organisation);
		},

		logout() {
			auth.logout();
		},

	},

};

</script>
