import Pusher from 'pusher-js';
import { reactive, watch } from 'vue';
import { useActivityBoardStore } from '@/stores/ActivityBoard.store';
import { useActivityBoardConnectStore } from '@/stores/ActivityBoardConnect.store';
import { storeToRefs } from "pinia";

// if(import.meta.env.NODE_ENV === 'development') {
// 	Pusher.logToConsole = true;
// }


export const state = reactive({
	connection: null,
});

let pusherInstance;

export function connectToPusher() {

	if (pusherInstance) {
		return;
	}

	pusherInstance = new Pusher(import.meta.env.VITE_PUSHER_KEY, {
		cluster: 'eu',
		forceTLS: true
	});

	// Listen for selected lesson
	const { relatedLesson } = storeToRefs(useActivityBoardStore());
	watch(relatedLesson, (newValue) => {
		if(newValue) {
			if (
				activityBoardChannels.length > 0 &&
					activityBoardChannels[0].name === newValue.broadcast_channel
			) {
				return;
			}

			stopListeningToActivityBoard();
			listenToActivityBoard(newValue);
		} else {
			stopListeningToActivityBoard();
		}
	});

	// Listen for classroom
	const { availableClassrooms } = storeToRefs(useActivityBoardConnectStore());
	watch(availableClassrooms, (newValue) => {
		if(newValue) {
			listenToClassroom(newValue);
		}
	}, {
		immediate: true,
	});

	return pusherInstance;

}

const activeListeners = [];
const activityBoardChannels = [];
function listenToActivityBoard(lesson) {

	console.log('Subscribe to pusher channel ', lesson.broadcast_channel);
	if (activeListeners.includes(lesson.broadcast_channel)) {
		return;
	}
	activeListeners.push(lesson.broadcast_channel);

	const channel = pusherInstance.subscribe(lesson.broadcast_channel);
	activityBoardChannels.push(channel);

	const activityBoardStore = useActivityBoardStore();

	channel.bind('pupil.checkin', function(data) {
		activityBoardStore.handleCheckInOnOtherClient(data);
	});

	channel.bind('pupil.checkout', function(data) {
		activityBoardStore.handleCheckOutOnOtherClient(data);
	});

	channel.bind('lesson.paused', function(data) {
		activityBoardStore.handlePauseOnOtherClient(data);
	});

	channel.bind('lesson.closed', function(data) {
		activityBoardStore.handleClosedOnOtherClient(data);
	});

	channel.bind('lesson.changed', function(data) {
		activityBoardStore.handleActivityBoardChanged(data);
	});

	channel.bind('lesson.removed', function(data) {
		activityBoardStore.handleActivityBoardRemoved(data);
	});

}

function stopListeningToActivityBoard() {

	activityBoardChannels.forEach(channel => {
		console.log('Unsubscribe from pusher channel ', channel.name);

		activeListeners.splice(activeListeners.indexOf(channel.name), 1);

		channel.unbind_all();
		channel.unsubscribe();
	});

	activityBoardChannels.length = 0;
}

function listenToClassroom(connectTokens) {

	if (!Array.isArray(connectTokens)) {
		return;
	}

	connectTokens.forEach(connectToken => {

		const classroom = connectToken.classroom;
		if (!classroom.broadcast_channel) {
			return;
		}

		console.log('Subscribe to pusher channel ', classroom.broadcast_channel);
		if (activeListeners.includes(classroom.broadcast_channel)) {
			return;
		}

		const channel = pusherInstance.subscribe(classroom.broadcast_channel);
		const activityBoardConnectStore = useActivityBoardConnectStore();

		channel.bind('lesson.created', function(data) {
			activityBoardConnectStore.handleLessonCreated(classroom, data);
		});

		channel.bind('lesson.changed', function(data) {
			activityBoardConnectStore.handleLessonUpdated(classroom, data);
		});

	});
}
