<template>

	<div>
		<div class="flex flex-col sm:flex-row gap-3 mb-6">
			<div v-if="showDateRangeSelector" class="w-full sm:w-1/2 lg:max-w-64">
				<DateRangeSelector
					v-model="dateRangeArray"
					:clearable="false"
					:disabled="dateRangeSelectorReadOnly"
					:readonly="dateRangeSelectorReadOnly"
				/>
			</div>
			<div v-if="showPupilSelector" class="w-full sm:w-1/2 lg:max-w-64">
				<sms-select v-model="selectedPupil" label="" class="select select-sm leading-4 bg-base" :options="pupilsForFilter"></sms-select>
			</div>
		</div>
	</div>

</template>

<script lang="ts">
import { mapStores } from "pinia";
import { usePupilsStore } from '@/stores/Pupils.store';

import DateRangeSelector from "./ui/DateRangeSelector.vue";
import { InsightsFilter } from "@/stores/Insights.store";
import { DateTime, Interval } from "luxon";
import { useDefaultOrderStore } from "@/stores/DefaultOrder.store";

export default {

	props: {
		modelValue: {
			type: InsightsFilter,
			required: true,
		},
		showPupilSelector: {
			type: Boolean,
			default: true,
		},
		showDateRangeSelector: {
			type: Boolean,
			default: true,
		},
		dateRangeSelectorReadOnly: {
			type: Boolean,
			default: false,
		},
	},

	emits: [
		'change',
		'update:modelValue',
	],

	components: {
		DateRangeSelector,
	},

	created() {
		this.pupilsStore.orderBy(this.defaultOrderStore.pupilsOrder);
	},

	computed: {
		...mapStores(usePupilsStore, useDefaultOrderStore),

		dateRangeArray: {
			get(): [Date, Date] {
				if(!this.modelValue.dateRange) {
					return null;
				} else {
					return [
						this.modelValue.dateRange.start,
						this.modelValue.dateRange.end
					];
				}
			},
			set(value) {
				// Not really setting the new value but rather emitting the updated model value (filter)
				// so the component gets re-rendered with the new value.
				// Also eliminates the need for a mutable local copy of the (unmutable) model value.
				let filterCopy = this.modelValue.clone();
				filterCopy.dateRange = Interval.fromDateTimes(
					DateTime.fromJSDate(value[0]).startOf('day'),
					DateTime.fromJSDate(value[1]).endOf('day')
				);
				this.$emit('update:modelValue', filterCopy);
				this.$emit('change', filterCopy);
			}
		},

		selectedPupil: {
			get(): string {
				return this.modelValue.pupilId;
			},
			set(value) {
				let filterCopy = this.modelValue.clone();
				filterCopy.pupilId = value;
				this.$emit('update:modelValue', filterCopy);
				this.$emit('change', filterCopy);
			}
		},

		pupilsForFilter() {
			let list = [];

			list.push({
				value: '',
				label: this.$t('All pupils'),
			});

			for (let pupil of this.pupilsStore.pupils) {
				list.push({
					value: pupil.id,
					label: pupil.firstName + ' ' + pupil.lastName,
				});
			}
			return list;
		},

	},

	mounted() {
		this.loadPupils();
	},

	methods: {
		async loadPupils() {
			await this.pupilsStore.load();
		},

		filterReset() {
			const cleanFilter = new InsightsFilter();
			this.$emit('update:modelValue', this.cleanFilter);
			this.$emit('change', this.cleanFilter);
		},
	},
}

</script>
