<template>

<div v-if="showPupilSelector">
	<PupilSelector @select="handlePupilSelection" @requestClose="hideEditor" />
</div>

<Drawer v-else :title="inUpdateMode? $t('Edit observation') : $t('Add observation for') + '...'" initialWidth="2xl" @requestUnmount="hideEditor">

	<template #default="{ hide }">

			<form @submit.prevent="save" class="p-6 flex flex-col space-y-5">

				<p v-if="observationsStore.errorMessage?.genericError" class="text-red-600 text-xs">
					{{ observationsStore.errorMessage?.genericError.toString() }}
				</p>

				<div class="font-bold pb-3 text-xl flex items-center gap-2 justify-center">
					<PupilSymbol :pupil="editableModel.pupil" class="w-14" />
					{{ editableModel.pupil.firstName }}
					{{ editableModel.pupil.lastName }}
				</div>

				<div v-if="canSelectActivity">
					<div class="flex items-start gap-3">
						<input type="checkbox" class="toggle toggle-lg" v-model="linkActivity" :disabled="activities.length? null : 'disabled'" />

						<div>{{ $t("Related to activity") }}...</div>
					</div>
					<div class="w-full overflow-hidden">
						<div v-if="activities.length === 0">
							<div class="text-base-content-light italic text-center py-2">{{ $t("No registered activities today yet") }}</div>
						</div>
						<div v-else class="w-full flex flex-nowrap py-5 overflow-x-scroll gap-1 px-3">
							<div v-for="pupilActivity in activities.slice().reverse()" class="relative bg-base-100 rounded-md" :class="[linkActivity? '' : 'opacity-20']">
								<!-- TODO add feedback icon to activity symbol if available -->
								<div v-if="isSelectedActivity(pupilActivity)" class="absolute z-10 bg-accent-focus rounded-full w-6 h-6 -top-2 -left-2 flex items-center justify-center">
									<CheckIcon class="w-4 h-4 text-white"></CheckIcon>
								</div>
								<ActivitySymbol
									v-if="pupilActivity.lessonItem"
									:activity="pupilActivity.lessonItem"
									:selected="false"
									class="w-20 rounded-lg ring-accent"
									:class="[ isSelectedActivity(pupilActivity)? 'ring-2' : ''  ]"
									@click="selectActivity(pupilActivity)"
								/>
							</div>
						</div>
					</div>
				</div>

				<div v-if="!canSelectActivity && editableModel.pupilActivity" class="text-center">
					<ActivitySymbol
						v-if="editableModel.pupilActivity.lessonItem"
						:activity="editableModel.pupilActivity.lessonItem"
						:selected="false"
						class="w-24"
					/>
				</div>

				<div class="grid grid-cols-6 gap-6">
					<div class="col-span-6 sm:col-span-6">
						<sms-textarea v-model="editableModel.note" :validationError="observationsStore.errorMessage?.fields.note" :label="$t('Note')" />
					</div>

					<Dropzone @files-dropped="filesDropped($event)" class="col-span-6 sm:col-span-6">

						<Attachments
							v-model="this.editableModel.files"
							v-model:uploading="attachmentsBusy"
							:preview="true"
							ref="attachmentsContainer"
							accept="image/*"
						/>

						<div class="mt-2 mb-2 pr-4 text-right">
							<span class="btn btn-primary btn-circle btn-lg"  @click="$refs.attachmentsContainer.openFileDialog()">
								<CameraIcon class="w-8 h-8"></CameraIcon>
							</span>
						</div>

					</Dropzone>
				</div>

				<div class="drawer-action-bar" :class="[inUpdateMode? 'justify-between' : 'justify-end']">
					<Dropdown v-if="inUpdateMode" position="top-start">
						<template #trigger>
							<button type="button" class="btn" :class="[saving? 'btn-disabled':'btn-danger btn-outline']">
								<span v-show="deleting" class="loading loading-spinner"></span>
								<TrashIcon class="w-5 h-5" />
							</button>
						</template>
						<template #content>
							<DropdownDialogConfirm :confirm-label="$t('Yes')" :cancel-label="$t('No')" confirm-style="danger" class="w-80" @confirm="confirmRemove()">
								<span class="text-danger">{{ $t('Are you sure?') }}</span>
							</DropdownDialogConfirm>
						</template>
					</Dropdown>

					<div class="flex justify-end gap-1">
						<button type="button" class="btn btn-ghost" @click="hide" :class="[saving || deleting? 'hidden':'']">
							{{ $t('Cancel') }}
						</button>
						<button type="submit" class="btn" :class="[!canSave? 'btn-disabled':'btn-primary']">
							<span v-show="saving" class="loading loading-spinner"></span>
							{{ $t('Save') }}
						</button>
					</div>
				</div>

			</form>

	</template>
</Drawer>

</template>


<script lang="ts">
import { mapStores } from 'pinia';
import { useObservationsStore } from '@/stores/Observations.store';
import { usePupilsStore } from '@/stores/Pupils.store';
import { usePupilActivitiesStore } from "@/stores/PupilActivities.store";
import { Observation } from '@/models/Observation.model';
import { Pupil } from '@/models/Pupil.model';

import { ApiErrors } from "@/stores/errors/ApiErrors";
import { Files } from "@/utils/Files.util";

// import Modal from "@/components/ui/Modal.vue";
import Drawer from "@/components/ui/Drawer.v2.vue";
import Dropdown from "@/components/ui/Dropdown.vue";
import DropdownDialogConfirm from "@/components/ui/DropdownDialogConfirm.vue";
import ActivitySymbol from './ui/symbols/ActivitySymbol.component.vue';
import PupilSymbol from './ui/symbols/PupilSymbol.component.vue';
import Switch from "@/components/ui/Switch.vue";
import PupilSelector from '@/components/PupilSelector.vue';
import { TrashIcon, CameraIcon, PhotoIcon, CheckIcon } from '@heroicons/vue/24/outline';
import {PupilActivity} from "@/models/PupilActivity.model";
import FilePreview from "./FilePreview.vue";
import { File } from "@/models/File.model";
import Attachments from "@/components/Attachments.vue";
import Dropzone from "@/components/Dropzone.vue";

export default {

	props: {
		observation: {
			type: Observation,
			required: false,
		},
		pupil: {
            type: Pupil,
			required: false,
        },
		pupilActivity: {
			type: PupilActivity,
			required: false
		}
	},

	components: {
		Attachments,
		FilePreview,
		// Modal,
		Drawer,
		Dropdown,
		DropdownDialogConfirm,
		ActivitySymbol,
		PupilSymbol,
		Switch,
		PupilSelector,
		TrashIcon, CameraIcon, PhotoIcon, CheckIcon,
		Dropzone,
	},

	data() {
		return {
			saving: false,
			deleting: false,
			originalModel: null,
			editableModel: null,
			linkActivity: false,
			selectedPupil: null,
			cachedSelectedActivity: null,
			attachmentsBusy: false,
		}
	},

  	computed: {
    	...mapStores(useObservationsStore, usePupilsStore, usePupilActivitiesStore),

		inUpdateMode() {
			return (this.observation && this.observation.id)? true : false;
		},

		showPupilSelector() {
			return !this.inUpdateMode && (!this.editableModel || !this.editableModel.pupil);
		},

		activities() {
			if(!this.editableModel?.pupil) {
				return [];
			}
            return this.pupilActivitiesStore.getActivityForPupilOnDate(new Date(), this.editableModel.pupil);
        },

		canSelectActivity() {
			// Is the activity defined by the parent component?
			if (this.pupilActivity) {
				return false;
			}

			return !this.inUpdateMode;
		},

		canSave() {
			return !this.saving && !this.deleting && !this.attachmentsBusy;
		}
	},

	async mounted() {
		if(this.inUpdateMode) {

			this.originalModel = this.observation;

			// clone the store model to allow for manipulation without instantly affecting affected the store
			this.editableModel = this.observation.clone();
			this.selectedPupil = this.observation.pupil;

		} else if(this.pupil && this.pupilActivity) {

			this.editableModel = this.observationsStore.new(this.pupil);
			this.selectedPupil = this.pupil;
			this.editableModel.pupilActivity = this.pupilActivity;
			this.linkActivity = true;

		} else if(this.pupil) {

			await this.handlePupilSelection(this.pupil);

		}
	},

	watch: {
		linkActivity(newValue, oldValue) {
			if (newValue === false && oldValue === true) {
				this.cachedSelectedActivity = this.editableModel.pupilActivity;
				this.editableModel.pupilActivity = null;
			} else if (newValue === true && oldValue === false) {
				if(!this.editableModel.pupilActivity && this.cachedSelectedActivity) {
					this.editableModel.pupilActivity = this.cachedSelectedActivity;
				}
			}
		}
	},

  	methods: {

		async handlePupilSelection(pupil) {
			this.editableModel = this.observationsStore.new(pupil);
			this.selectedPupil = pupil;
			await this.loadPupilActivities();
		},

		async loadPupilActivities() {
			await this.pupilActivitiesStore.loadActivityOnDate(new Date());
        	this.selectMostRecentActivity();
		},

		isSelectedActivity(pupilActivity: PupilActivity) {
			return this.editableModel.pupilActivity && this.editableModel.pupilActivity.id === pupilActivity.id;
		},

		selectMostRecentActivity() {
            // const activities = this.pupilActivitiesStore.getActivityForPupilOnDate(new Date(), this.pupil);
            if (this.activities.length === 0) {
                this.linkActivity = false;
                return;
            }

            this.editableModel.pupilActivity = this.activities[this.activities.length - 1];
            this.linkActivity = true;
        },

        selectActivity(activity) {
            this.linkActivity = true;
            this.editableModel.pupilActivity = activity;
        },

        async selectFile(file) {
            if (!file) {
                return;
            }

            this.editableModel.fileName = file.name;
            this.editableModel.fileContent = await Files.toBase64(file);
        },

		hideEditor (observation: Observation | null) {
			this.observationsStore.clearErrorMessage();
			this.$emit('requestClose', observation);
		},

		async confirmRemove() {

			if (this.deleting) {
				return;
			}
			this.deleting = true;

			try {
				await this.observationsStore.delete(this.originalModel);
				this.deleting = false;

				this.hideEditor(null);

				this.$notify({
					text: this.$t('The observation has been removed'),
					type: "success",
				});

			} catch (e: any) {
				if (!(e instanceof ApiErrors)) {
					throw e;
				}
			}
		},

		async save() {

			if (!this.canSave) {
				return;
			}
			this.saving = true;

			try {
				await this.observationsStore.save(this.editableModel);

				this.saving = false;

				if (this.observation) {
					this.observation.setFromModel(this.editableModel);
					this.hideEditor(this.observation);
				} else {
					this.hideEditor(this.editableModel);
				}

				this.$notify({
					text: this.$t('The observation has been saved'),
					type: "success",
				});

			} catch (e) {
				// error message is populated now.
				this.saving = false;
			}
    	},

		filesDropped(files) {
			this.$refs.attachmentsContainer.filesDropped(files);
		}
  	},

}
</script>
