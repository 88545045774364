<template>
<TheStdLayout>

	<template #pageheader>{{ $t('Icon library') }}</template>

	<LoadingScreen v-if="loading" />

	<div class="px-4 py-6 mx-auto sm:px-6 md:px-8" v-if="loaded">

		<!-- <div v-if="classroom">{{ $t('You are currently managing icon libraries for classroom {classroom}.', { classroom: classroom.name })}}</div> -->
		<div v-if="!classroom" class="alert alert-warning text-sm mb-4 print:hidden">
			<div class="flex gap-2 items-start">
				<ExclamationTriangleIcon class="w-5 h-5" />
				<span>{{ $t('The icon libraries listed here are available to all teachers.')}}</span>
			</div>
		</div>

		<FloatingPlusButton @push="handleLibraryCreateRequest" />

		<table class="divide-y divide-base-200 w-full">

			<thead>
				<tr>
					<th scope="col" class="table-col-title">
						<SortableHeader property="name" :order="iconLibrariesStore.order" @change="iconLibrariesStore.changeOrder">{{$t('Name')}}</SortableHeader>
					</th>

					<th scope="col" class="table-col-title">
						<SortableHeader property="group" :order="iconLibrariesStore.order" @change="iconLibrariesStore.changeOrder">{{$t('Symbols for')}}</SortableHeader>
					</th>

					<th scope="col" class="table-col-title">
						{{$tc('Icon', 2)}}
					</th>

					<th scope="col" class="px-6 py-3">
						<span class="sr-only">{{$t('Actions')}}</span>
					</th>
				</tr>
			</thead>

			<tbody class="bg-base divide-y divide-base-200">

				<tr>
					<td class="px-4 py-3 whitespace-nowrap">Smart Symbols</td>
					<td class="px-4">{{ $tc('Pupil', 2) }}</td>
					<td class="py-2">
						<router-link :to="getIconLibraryUrl('pupils')">
							<IconsPreview :svgIcons="this.defaultPupilIcons" :editable="false" :icon-library="{ id: 'pupils' }" :to="getIconLibraryUrl('pupils')" />
						</router-link>
					</td>
					<td></td>
				</tr>

				<tr>
					<td class="px-4 py-3 whitespace-nowrap">Smart Symbols</td>
					<td class="px-4">{{ $tc('Activity', 2) }}</td>
					<td class="py-2">
						<router-link :to="getIconLibraryUrl('activities')">
							<IconsPreview :svgIcons="this.defaultActivityIcons" :editable="false" :icon-library="{ id: 'activities' }" :to="getIconLibraryUrl('activities')" />
						</router-link>
					</td>
					<td></td>
				</tr>

				<tr v-for="iconLibrary in iconLibrariesStore.iconLibraries">
					<td class="px-4 py-3 print:py-1 whitespace-nowrap">{{iconLibrary.name}}</td>
					<td class="px-4">{{ iconLibrary.getGroupName() === 'Activity'? $tc('Activity', 2) : $tc('Pupil', 2) }}</td>
					<td class="py-3 print:py-1">
						<router-link :to="getIconLibraryUrl(iconLibrary.id)">
							<IconsPreview
								:iconLibrary="iconLibrary"
								:editable="iconLibrary.isEditable(this.scope)"
								@clickAdd="addNewIcon(iconLibrary)"
								:to="getIconLibraryUrl(iconLibrary.id)"
							/>
						</router-link>
					</td>

					<td class="px-4 py-3 print:py-1 whitespace-nowrap text-right text-sm font-medium flex justify-end gap-2 sm:gap-4">
						<div class="print:hidden">
							<button
								v-if="iconLibrary.isEditable(this.scope)"
								@click="handleLibraryEditRequest(iconLibrary)"
								class="btn btn-ghost btn-circle bg-base-100"
							>
								<PencilIcon class="w-5 h-5"></PencilIcon>
							</button>
						</div>
					</td>
				</tr>

			</tbody>
		</table>
	</div>

	<!-- Modal -->
	<CustomIconsEditLibraryModal v-if="showLibraryFormModal" :library="iconLibrary" :scope="scope" @requestClose="closeIconLibrary" />

</TheStdLayout>
</template>

<script lang="ts">
import { mapStores } from "pinia";
import { useIconsStore } from "@/stores/Icons.store";
import { useIconLibrariesStore } from "@/stores/IconLibraries.store";
import { useClassroomsStore } from "@/stores/Classrooms.store";

import {
	ExclamationTriangleIcon, PlusIcon, PencilIcon,
} from '@heroicons/vue/24/outline';

import IconsPreview from "@/components/IconsPreview.vue";

import CustomIconsEditLibraryModal from "@/components/IconLibraryEditModal.vue";
import SortableHeader from "@/components/ui/SortableHeader.vue";
import LoadingScreen from "@/components/ui/LoadingScreen.vue";

export default {

	data() {
		return {
			classroom: null,
			iconLibrary: null,
			showLibraryFormModal: false,

			loaded: false,
			loading: false,

			defaultPupilIcons: [
				'/sprites/fa-light.svg#apple-whole',
				'/sprites/icon-4000-cust.svg#banana',
				'/sprites/icon-4000-cust.svg#lemon',
				'/sprites/icon-4000-cust.svg#pear',
				'/sprites/icon-4000.svg#cherry',
				'/sprites/icon-4000.svg#grapes'
			],

			defaultActivityIcons: [
				'/sprites/icon-4000-cust.svg#number-1',
				'/sprites/icon-4000-cust.svg#number-2',
				'/sprites/icon-4000-cust.svg#number-3',
				'/sprites/icon-4000-cust.svg#number-4',
				'/sprites/icon-4000-cust.svg#number-5',
				'/sprites/icon-4000-cust.svg#number-dots-1',
			]
		}
	},
	components: {
		LoadingScreen,
		SortableHeader,
		CustomIconsEditLibraryModal,
		IconsPreview,
		ExclamationTriangleIcon, PlusIcon, PencilIcon,
	},
	props: {
		scope: String
	},

	computed:{
		...mapStores(useIconsStore, useIconLibrariesStore),
	},

	mounted() {
		this.iconsStore.loadAllIcons();
		this.loadScopedLibraries();

	},

	watch: {
		scope(scope, oldScope) {
			this.loadScopedLibraries();
		}
	},

	methods: {

		getIconLibraryUrl(iconLibraryId) {
			switch (this.scope) {
				case 'classroom':
					return {
						name: 'classroom.icon-library.view',
						params: {
							classroomId: useClassroomsStore().currentClassroom.id,
							libraryId: iconLibraryId
						}
					};

				case 'global':
				default:
					return {
						name: 'icon-library.view',
						params: {
							libraryId: iconLibraryId
						}
					};
			}
		},

		async loadScopedLibraries() {

			this.loaded = false;

			switch (this.scope) {
				case 'classroom':
					this.classroom = useClassroomsStore().currentClassroom;
					if (!this.iconLibrariesStore.areIconLibrariesForClassroomLoaded(this.classroom.id)) {
						this.loading = true;
					}

					await this.iconLibrariesStore.getIconLibrariesForClassroom(useClassroomsStore().currentClassroom.id);
					break;

				case 'global':
				default:
					if (!this.iconLibrariesStore.areGlobalIconLibrariesLoaded()) {
						this.loading = true;
					}

					this.classroom = null;
					await this.iconLibrariesStore.getIconLibraries()
					break;
			}

			this.loaded = true;
			this.loading = false;
		},

		handleLibraryCreateRequest() {
			this.showLibraryFormModal = true;
		},

		handleLibraryEditRequest(library) {
			this.iconLibrary = library;
			this.showLibraryFormModal = true;
		},

		addNewIcon(iconLibrary) {

			let routeName;
			switch (this.scope) {
				case 'classroom':
					routeName = 'classroom.icon-library.view';
					break;

				case 'global':
				default:
					routeName = 'icon-library.view';
					break;
			}

			this.$router.push({
				name: routeName,
				params: {
					libraryId: iconLibrary.id,
					classroomId: iconLibrary.classroomId
				},
				state: {
					create: '1'
				}
			});

		},

		closeIconLibrary() {
			this.showLibraryFormModal = false;
			this.iconLibrary = null;
		}
	}
}
</script>
