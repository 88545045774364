<template>
<TheStdLayout :title="$tc('Observation', 2)">

	<Fancybox :options="{Toolbar: {display: {right: ['close']}}}"></Fancybox>

	<div class="px-4 py-6 mx-auto sm:px-6 md:px-8">

		<ObservationsFilter class="print:hidden" />

		<FloatingPlusButton @push="handleObservationCreateRequest" />

		<EmptyState v-if="loaded && observationsStore.observations.length === 0"
			:title="$t('No observations found for this period')"
		/>

		<table v-else class="divide-y divide-base-200 w-full">

			<thead>
				<tr>
					<th scope="col" class="table-col-title">
						{{ $t('Registered at') }}
					</th>
					<th scope="col" class="table-col-title">
						{{ $tc('Pupil', 1) }}
					</th>
					<th scope="col" class="table-col-title">
						{{ $tc('Activity', 1) }}
					</th>
					<th scope="col" class="table-col-title">
						{{ $tc('Observation', 1) }}
					</th>
					<th scope="col" class="px-6 py-3">
						<span class="sr-only">{{$t('Actions')}}</span>
					</th>
				</tr>
			</thead>

			<tbody class="bg-base divide-y divide-base-200">

				<tr v-show="!loaded">
					<td colspan="4">
						<Skeleton content-type="datalist" :items="3" class="m-4" :with-thumb="false" />
					</td>
				</tr>

				<tr v-show="loaded" v-for="(observation, key) in observationsStore.observations" :key="key">
					<td class="px-4 py-3 align-top">
						{{ localDateTime(observation.date) }}
					</td>

					<td class="px-4 py-3 align-top">
						{{ observation.pupil?.firstName }} {{ observation.pupil?.lastName }}
					</td>

    				<td class="px-4 py-3 align-top">
						<span v-if="observation.pupilActivity?.lessonItem?.getDescriptiveTitle()">
							{{ observation.pupilActivity?.lessonItem?.getDescriptiveTitle() }}
						</span>
					</td>
					<td class="px-4 py-3 break-normal whitespace-pre-line text-sm">
						<div v-if="observation.files.length > 0" class="flex">
							<template v-for="file in observation.files">
								<div v-if="file.type === 'image'" class="w-24 mr-3 mb-2">
									<FilePreview :file="file" data-fancybox="gallery" />
								</div>
							</template>
						</div>

						<div v-if="observation.note">{{ observation.note }}</div>
					</td>
					<td class="px-4 py-3 print:py-1 whitespace-nowrap text-right text-sm font-medium flex justify-end gap-2 sm:gap-4">
						<div class="flex-row print:hidden">
							<button class="btn btn-ghost btn-circle bg-base-100" @click="handleActivityEditRequest(observation)">
								<PencilIcon class="w-5 h-5"></PencilIcon>
							</button>
						</div>
					</td>

				</tr>

			</tbody>
		</table>

		<Pagination v-if="observationsStore.currentPage" :page="observationsStore.currentPage" @requestPage="observationsStore.goToPage"  />
  	</div>

	<ObservationEditor v-if="showObservationEditor" @requestClose="closeObservationForm" :observation="editableModel" />

</TheStdLayout>
</template>

<script lang="ts">
import { mapStores } from "pinia";
import { DateTime } from "luxon";

import { useActivitiesStore } from "@/stores/Activities.store";
import { useObservationsStore } from "@/stores/Observations.store";
import { usePupilActivitiesStore } from "@/stores/PupilActivities.store";

import ObservationEditor from "@/components/ObservationEditor.vue";
import ObservationsFilter from "./ObservationsFilter.vue";

import Skeleton from '@/components/ui/Skeleton.vue';
import { PlusIcon, PencilIcon, MagnifyingGlassIcon } from '@heroicons/vue/24/outline';
import Pagination from "@/components/ui/Pagination.vue";
import FilePreview from "./FilePreview.vue";
import Fancybox from "./ui/Fancybox.vue";
import {Observation} from "@/models/Observation.model";

export default {

	data() {
		return {
			loaded: false,
			activity: null,
			showObservationEditor: false,
			editableModel: null
		};
	},

	components: {
		FilePreview,
		Pagination,
		Skeleton,
		ObservationEditor,
		ObservationsFilter,
		PlusIcon, PencilIcon,
		Fancybox,
	},

	computed: {
		...mapStores(useActivitiesStore),
		...mapStores(useObservationsStore),
		...mapStores(usePupilActivitiesStore),

		localDateTime() {
			return (date: Date) => {
				return DateTime.fromJSDate(date).toLocaleString(DateTime.DATETIME_SHORT);
			};
		}
	},

	mounted() {
		this.load();
	},

	methods: {

		async load() {
			await Promise.all([
				this.observationsStore.loadFirstPage(),
				this.pupilActivitiesStore.loadActivityOnDate(new Date())
			]);
			this.loaded = true;
		},

		async loadActivities() {
			await this.activitiesStore.load();
		},

		closeObservationForm() {
			this.showObservationEditor = false;
			this.editableModel = null;
		},

		handleObservationCreateRequest() {
			this.editableModel = null;
			this.showObservationEditor = true;
		},

		handleActivityEditRequest(observation: Observation) {
			this.editableModel = observation;
			this.showObservationEditor = true;
		},
	},
};

</script>
