<template>
    <div class="flex">
        <button v-for="option in options"
            type="button"
            class="px-5 py-3 text-base-content border-neutral"
            :class="option.value === selectedOption ? 'border-b-2 hover:cursor-default' : 'text-opacity-40 hover:text-opacity-75'"
            @click="changeTab(option.value)"
        >
            {{ option.label }}
        </button>
    </div>
</template>

<script lang="ts">
export default {
    emits: [
		'change'
	],
    props: {
        options: {
            type: Array,
            required: true,
        },
        value: {
            type: String,
            default: null,
        },
    },
    computed: {
        selectedOption() {
            if (this.value && this.options.find(option => option.value === this.value)) {
                return this.value;
            } else {
                return this.options[0].value;
            }
        },
    },
    methods: {
        changeTab(optionValue: string) {
			if(optionValue !== this.selectedOption) {
				this.$emit('change', optionValue);
			}
        },
    },
}
</script>