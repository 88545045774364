<template>

	<Fancybox :options="{Toolbar: {display: {right: ['close']}}}"></Fancybox>

	<div v-if="loading">
		<div class="flex justify-center">
			<span class="loading loading-spinner loading-md"></span>
		</div>
	</div>

	<table v-if="!loading" class="divide-y divide-gray-200 w-full table-fixed mb-10">
		<thead class="">
		<tr>
			<th v-if="withNameColumn" scope="col" class="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/6">
				{{ $t('Name') }}
			</th>
			<th scope="col" class="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/6">
				{{ $t('Activity') }}
			</th>
			<th scope="col" class="px-3 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider text-right hidden lg:table-cell w-1/12">
				{{ $t('Length of time') }}
			</th>
			<th scope="col" class="px-3 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider w-1/12">

			</th>
			<th scope="col" class="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
				{{ $tc('Observation',2) }}
			</th>
		</tr>
		</thead>

		<tr v-if="feedback.length === 0">
			<td colspan="6" class="px-3 py-8 whitespace-nowrap text-sm text-gray-500 text-center">
				{{ $t('No activity was recorded during this period' )}}
			</td>
		</tr>

		<tbody class="bg-white divide-y divide-gray-200" v-if="feedback.length > 0">

			<template v-for="(pupilActivities, pupilIndex) in feedback">
				<tr v-for="(pupilActivity, activityIndex) in pupilActivities.activities">
					<td v-if="withNameColumn && activityIndex === 0" class="px-3 py-2 border-r overflow-hidden truncate" valign="top" :rowspan="pupilActivities.activities.length">
						{{ pupilActivity.pupil.getFullName() }}
					</td>
					<td class="px-3 py-3" valign="top">
						<span class="flex flex-col items-start">
							<span class="text-gray-400 mr-2 text-xs flex gap-2">
								<span>{{ readableDate(pupilActivity.checkin) }}</span>
								<span>{{ readableTime(pupilActivity.checkin) }}</span>
							</span>

							<template v-if="pupilActivity.lessonItem.lessonPlan">
								{{ pupilActivity.activity.name }} / {{ pupilActivity.lessonItem.lessonPlan.title }}
							</template>

							<template v-else>
								{{ pupilActivity.activity.name }}
							</template>

						</span>
					</td>

					<td class="px-3 py-3 whitespace-nowrap text-sm text-right hidden lg:table-cell" valign="top">

						<span v-if="pupilActivity.time_spent === 0">
							?
						</span>

						<span v-if="pupilActivity.duration" :title="durationTitle(pupilActivity)">
							{{ readableDuration(pupilActivity.duration) }}
						</span>
					</td>

					<td class="px-3 py-3 whitespace-nowrap flex justify-center" valign="top">
						<svg v-if="pupilActivity.feedback === -1" class="w-6 h-6 text-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
							<path d="M22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12ZM7.71 15.3C7.42 15.6 7.43 16.07 7.72 16.36C8.02 16.65 8.49 16.65 8.78 16.35C9.63 15.49 10.77 15 12 15C13.22 15 14.37 15.49 15.21 16.34C15.5 16.64 15.98 16.64 16.27 16.35C16.57 16.06 16.57 15.59 16.28 15.29C15.16 14.15 13.63 13.5 12 13.5C10.37 13.5 8.83 14.16 7.71 15.3ZM7.75 10C7.75 10.69 8.31 11.25 9 11.25C9.69 11.25 10.25 10.69 10.25 10C10.25 9.31 9.69 8.75 9 8.75C8.31 8.75 7.75 9.31 7.75 10ZM13.75 10C13.75 10.69 14.31 11.25 15 11.25C15.69 11.25 16.25 10.69 16.25 10C16.25 9.31 15.69 8.75 15 8.75C14.31 8.75 13.75 9.31 13.75 10Z" id="e2TMzgzsCi" />
						</svg>

						<svg v-if="pupilActivity.feedback === 0" class="w-6 h-6 text-yellow-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
							<path d="M2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2C6.48 2 2 6.48 2 12ZM9 11.25C8.31 11.25 7.75 10.69 7.75 10C7.75 9.31 8.31 8.75 9 8.75C9.69 8.75 10.25 9.31 10.25 10C10.25 10.69 9.69 11.25 9 11.25ZM15 11.25C14.31 11.25 13.75 10.69 13.75 10C13.75 9.31 14.31 8.75 15 8.75C15.69 8.75 16.25 9.31 16.25 10C16.25 10.69 15.69 11.25 15 11.25ZM8.25 15C9 15 15 15 15.75 15C16.16 15 16.5 15.34 16.5 15.75C16.5 16.16 16.16 16.5 15.75 16.5C15 16.5 9 16.5 8.25 16.5C7.84 16.5 7.5 16.16 7.5 15.75C7.5 15.34 7.84 15 8.25 15Z" />
						</svg>

						<svg v-if="pupilActivity.feedback === 1" class="w-6 h-6 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
							<path d="M22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12ZM7.41 14.66C7.08 14.92 7.03 15.39 7.28 15.71C8.41 17.15 10.14 18 12 18C13.86 18 15.58 17.15 16.71 15.72C16.97 15.39 16.91 14.92 16.59 14.66C16.26 14.41 15.79 14.46 15.53 14.79C14.69 15.86 13.4 16.5 12 16.5C10.6 16.5 9.31 15.86 8.46 14.78C8.21 14.46 7.73 14.4 7.41 14.66ZM7.75 10C7.75 10.69 8.31 11.25 9 11.25C9.69 11.25 10.25 10.69 10.25 10C10.25 9.31 9.69 8.75 9 8.75C8.31 8.75 7.75 9.31 7.75 10ZM13.75 10C13.75 10.69 14.31 11.25 15 11.25C15.69 11.25 16.25 10.69 16.25 10C16.25 9.31 15.69 8.75 15 8.75C14.31 8.75 13.75 9.31 13.75 10Z" />
						</svg>
					</td>

					<td class="px-3 py-3 text-sm">

						<div class="flex flex-col">
							<div class="flex flex-col gap-4 " v-if="pupilActivity.observations">

								<div class="flex" v-for="observation in pupilActivity.observations">

									<div class="flex-grow">

										<div class="flex mb-3" v-if="observation.files.length > 0">
											<template v-for="file in observation.files">
												<div v-if="file.type === 'image'" class="w-24 mr-3">
													<FilePreview :file="file" data-fancybox="gallery"/>
												</div>
											</template>
										</div>

										<div class="max-h-20 overflow-auto">
											<template v-if="observation.note">
												<div class="text-gray-200 float-left mr-1 mb-1">
													<svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3"
														 viewBox="0 0 512 512" fill="currentColor">
														<path
															d="M464 256h-80v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8c-88.4 0-160 71.6-160 160v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48zm-288 0H96v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8C71.6 32 0 103.6 0 192v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48z"/>
													</svg>
												</div>
												<div>{{ observation.note }}</div>
											</template>
										</div>

									</div>

									<div class="px-3 py-3 pr-0">
										<button class="btn btn-ghost btn-circle bg-base-100"
												@click="editObservation(observation, pupilActivity)">
											<PencilIcon class="w-5 h-5"></PencilIcon>
										</button>
									</div>
								</div>

							</div>
						</div>

						<!-- New observation button -->
						<div class="px-3 py-3 pr-0 whitespace-nowrap text-sm text-right" valign="top" v-if="pupilActivity.observations.length === 0">
							<button type="button" class="btn btn-ghost btn-circle bg-base-100" @click.prevent="createObservation(pupilActivities.pupil, pupilActivity)">
								<svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 512 512" stroke="currentColor">
									<path d="M448 0H64C28.7 0 0 28.7 0 64v288c0 35.3 28.7 64 64 64h96v84c0 7.1 5.8 12 12 12 2.4 0 4.9-.7 7.1-2.4L304 416h144c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64zm32 352c0 17.6-14.4 32-32 32H293.3l-8.5 6.4L192 460v-76H64c-17.6 0-32-14.4-32-32V64c0-17.6 14.4-32 32-32h384c17.6 0 32 14.4 32 32v288zM344 192h-72v-72c0-4.4-3.6-8-8-8h-16c-4.4 0-8 3.6-8 8v72h-72c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h72v72c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8v-72h72c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8z" />
								</svg>
							</button>
						</div>
					</td>
				</tr>
			</template>

		</tbody>
	</table>

	<ObservationEditor v-if="showObservationFormModal" :observation="editableObservation" :pupil="observationPupil" :pupilActivity="observationActivity" @requestClose="closeObservationPopup" />

</template>

<script lang="ts">
import { mapState, mapStores } from "pinia";
import {InsightsFilter, useInsightsStore} from "@/stores/Insights.store";
import { usePupilsStore } from "@/stores/Pupils.store";
import { DateTime, Duration } from "luxon";
import ObservationEditor from "@/components/ObservationEditor.vue";
import {Pupil} from "@/models/Pupil.model";
import {PupilActivity} from "@/models/PupilActivity.model";
import FilePreview from "./FilePreview.vue";
import Fancybox from "./ui/Fancybox.vue";
import { PlusIcon, PencilIcon } from '@heroicons/vue/24/outline';
import { useObservationsStore } from "@/stores/Observations.store";
import { Observation } from "@/models/Observation.model";

// Set to TRUE to, when duration is less than 1 minute, show seconds.
const SHOW_SUB_MINUTE_SECONDS = true;

export default {
	components: {
		PencilIcon,
		ObservationEditor,
		Fancybox,
		FilePreview,
	},

	props: {
		filter: {
			type: InsightsFilter,
			required: true
		},
		withNameColumn: {
			type: Boolean,
			default: true,
		}
	},

	computed: {
		...mapStores(useInsightsStore, usePupilsStore, useObservationsStore),
		...mapState(useInsightsStore, [ 'feedback', 'maxTotalTimeSpentInActivity', 'totalTimeSpent' ]),
	},

	data() {
		return {
			maxBarWidth: 300,
			loading: false,
			observationPupil: null,
			observationActivity: null,
			showObservationFormModal: false,
			editableObservation: null,
			selectedInsight: null,
		}
	},

	async mounted() {
		await this.reload();
	},

	watch: {
		filter() {
			this.reload();
		}
	},

	methods: {

		async reload() {
			this.loading = true;
			await this.insightsStore.loadFeedback(this.filter);
			this.loading = false;
		},

		calcBarWidth(value: number) {
			return value * (this.maxBarWidth / this.maxTotalTimeSpentInActivity);
		},

		formatDuration(seconds: number) {
			return Math.floor(seconds / 60) + 'm ' + (seconds % 60) + 's';
		},

		percentageOfAllTimeSpent(seconds: number) {
			return Math.round(100 * seconds / this.totalTimeSpent);
		},

		readableDuration(duration: Duration) {

			const seconds = Math.round(duration.toMillis() / 1000);
			const minutes = Math.floor(seconds / 60);

			if (minutes === 0) {
				return seconds + ' ' + this.$t('sec');
			}

			return minutes + ' ' + this.$t('min');

			/*
// Alternative code below introduces performance issues
			if (SHOW_SUB_MINUTE_SECONDS && duration.toMillis() < 60 * 1000) {
				return duration.shiftTo('seconds').toHuman({
					unitDisplay: 'short',
					type: 'unit',
				});
			}

			let dur = duration.mapUnits((x,u) => u === 'milliseconds'? Math.round(x / 60000) * 60000 : x);

			return dur.shiftTo('minutes').toHuman({
				unitDisplay: 'short',
				type: 'unit',
			});*/
		},

		durationTitle(pupilActivity: PupilActivity) {
			if (!pupilActivity.breakDuration) {
				return this.readableDuration(pupilActivity.duration);
			}

			let duration = this.readableDuration(pupilActivity.duration.plus(pupilActivity.breakDuration));
			duration += ' (- ' + this.readableDuration(pupilActivity.breakDuration) + ' breaks)';

			return duration;
		},

		readableDate(date: Date) {
			return DateTime.fromJSDate(date).toLocaleString({day: 'numeric', month: 'short'});
		},

		readableTime(date: Date) {
			return DateTime.fromJSDate(date).toLocaleString(DateTime.TIME_SIMPLE);
		},

		closeObservationPopup(observation: Observation) {

			if(!this.editableObservation && observation) {
				this.observationActivity.observations.push(observation);
			} else if (this.editableObservation && observation === null) {
				this.observationActivity.removeObservation(this.editableObservation);
			}

			this.editableObservation = null;
			this.showObservationFormModal = false;
			this.observationPupil = null;
			this.observationActivity = null;
		},

		createObservation(pupil: Pupil, pupilActivity: PupilActivity) {
			this.observationPupil = pupil;
			this.observationActivity = pupilActivity;
			this.showObservationFormModal = true;
		},

		editObservation(observation: Observation, pupilActivity: PupilActivity) {
			this.editableObservation = observation;
			this.observationActivity = pupilActivity;
			this.showObservationFormModal = true;
		}

	}

}
</script>
