<template>

	<!--
	just to make sure these classes are not getting treeshaked as they are actually used (but created dynamically)
	<div class="bg-green-0 bg-green-50 bg-green-100 bg-green-150 bg-green-200 bg-green-250 bg-green-300 bg-green-350 bg-green-400 bg-green-450 bg-green-500 bg-green-550 bg-green-600 bg-green-650 bg-green-700 bg-green-750 bg-green-800 bg-green-850 bg-green-900 bg-green-950" />
	-->

	<div v-if="loading">
		<div class="flex justify-center">
			<span class="loading loading-spinner loading-md"></span>
		</div>
	</div>

	<div v-if="!loading && colPupils.length === 1">{{ $t('Insufficient pupils to generate this report' )}}</div>

	<template v-if="!loading && colPupils.length > 1">

		<div class="alert alert-info mb-8">
			{{ $t('#social-matrix-info') }}
		</div>

		<table class="h-1 border-collapse table-fixed" id="social-matrix">
			<thead class="sticky top-36 z-20">
				<tr class="">
					<th class="bg-white bg-opacity-90"></th>
					<th v-for="pupil in colPupils"
						class="bg-white bg-opacity-90"
						:class="[!pupil.active ? 'line-through text-gray-400': '']"
						style="writing-mode: vertical-lr;"
						:title="pupil.getFullName()"
					>
							<!-- wrap in div for writing mode to work in Safari -->
							<div class="text-right h-48 w-4 md:w-6 xl:w-8 truncate py-4 font-normal text-sm sm:text-" style="writing-mode: vertical-lr;">{{ pupil.getFullName() }}</div>
					</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(pupilA, rowIndex) in rowPupils" class="h-full group transition-colors hover:outline outline-1 -outline-offset-1 outline-green-300 rounded" @click="selectRow" :data-row-index="rowIndex">
					<th class="truncate max-w-40 text-left pr-4 h-4 md:h-6 xl:h-8 font-normal text-sm" :title="pupilA.getFullName()">{{ pupilA.getFullName() }}</th>
					<td v-for="pupilB in colPupils" class="relative h-full box-border p-0.5">
						<template v-if="pupilA.id !== pupilB.id">
							<div :title="duration(insightsStore.getSocialMatrixValue(pupilA, pupilB).duration)"
								class="relative w-full h-full flex items-center justify-center rounded xl:rounded-lg"
								:class="['bg-green-' + colorNumber(insightsStore.getSocialMatrixValue(pupilA, pupilB))]"
							>
								<span class="absolute -bottom-2 shadow-md z-10 text-xs font-semibold bg-black text-white bg-opacity-60 rounded px-1 group-hover:visible" :class="[!isSelectedRow(rowIndex)? 'invisible' : '']">
									{{ percentage(insightsStore.getSocialMatrixValue(pupilA, pupilB).percentage ?? null) }}
								</span>
							</div>
						</template>
						<template v-else>
							<div class="w-full h-full rounded xl:rounded-lg bg-base-200"></div>
						</template>
					</td>
				</tr>
			</tbody>
		</table>

	</template>

</template>


<style>
table#social-matrix td:first-child,
table#social-matrix th:first-child {
	@apply rounded-l pl-4;
}
table#social-matrix td:last-child {
	@apply rounded-r;
}
</style>


<script lang="ts">
import { mapState, mapStores } from "pinia";
import { InsightsFilter, PupilInteraction, useInsightsStore } from "@/stores/Insights.store";
import { usePupilsStore } from "@/stores/Pupils.store";
import { Pupil } from "@/models/Pupil.model";

export default {

	props: {

		filter: {
			type: InsightsFilter,
			required: true
		},

	},

	computed: {
		...mapStores(useInsightsStore, usePupilsStore),
		...mapState(useInsightsStore, [
			'activities',
			'maxTotalTimeSpentInActivity',
			'totalTimeSpent',
			'socialMatrixRowPupils',
			'socialMatrixColPupils'
		]),

		rowPupils(): Pupil[] {
			if (this.filter.pupilId) {
				return [this.pupilsStore.findById(this.filter.pupilId)];
			}
			return this.socialMatrixRowPupils;
		},

		colPupils(): Pupil[] {
			if (this.filter.pupilId) {
				return this.socialMatrixColPupils.filter(pupil => pupil.id !== this.filter.pupilId);
			}
			return this.socialMatrixColPupils;
		},
	},

	data() {
		return {
			maxBarWidth: 300,
			loading: true,
			selectedRow: null,
		}
	},

	async mounted() {
		await this.reload();
	},

	watch: {
		filter() {
			this.reload();
		}
	},

	methods: {

		async reload() {
			this.loading = true;
			await this.insightsStore.loadSocialMatrix(this.filter);

			this.loading = false;
		},

		duration(duration: Duration) {
			const seconds = duration.toMillis() / 1000;
			const hours = Math.floor(seconds / 3600);
			const minutes = Math.round((seconds % 3600) / 60);

			return hours + this.$t('h') + ' ' + minutes + this.$t('m');
			// Alternative code below introduces performance issues
			//return duration.toFormat("hh'" + this.$t('u') + "'mm'" + this.$t('m') + "'")
		},

		percentage(percentage: number) {
			if(!percentage) {
				return '';
			}
			return Math.round(percentage) + '%';
		},

		colorNumber(pupilInteraction: PupilInteraction) {

			if (this.insightsStore.socialMaxTimeSpent.equals(this.insightsStore.socialMinTimeSpent)) {
				return 0
			}

			const colorInterval = this.insightsStore.socialMaxTimeSpent
				.minus(this.insightsStore.socialMinTimeSpent)
				.toMillis() / 19;

			return Math.ceil(
				(pupilInteraction.duration.minus(this.insightsStore.socialMinTimeSpent).toMillis()) / colorInterval
			) * 50;
		},

		selectRow(event) {
			if(this.selectedRow) {
				this.selectedRow.classList.remove('bg-base-200');
			}
			this.newlySelectedRow = event.target.closest('tr');
			if( this.newlySelectedRow === this.selectedRow) {
				this.selectedRow = null;
			} else {
				this.selectedRow = this.newlySelectedRow;
				this.selectedRow.classList.add('bg-base-200');
			}
		},

		isSelectedRow(rowIndex) {
			if(rowIndex === 0 && this.rowPupils.length === 1) {
				return true;
			}
			return this.selectedRow && this.selectedRow.dataset.rowIndex === rowIndex.toString();
		},

	}

}
</script>
