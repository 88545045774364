import { defineStore } from 'pinia';
import {orgApi} from "@/utils/Api.util";
import { Pupil } from "@/models/Pupil.model";
import { Symbol } from "@/models/Symbol.model";
import { Icon } from "@/models/Icon.model";
import { ErrorMessage } from "@/stores/errors/ErrorMessage";
import { ApiErrors } from "@/stores/errors/ApiErrors";
import { OrderDirection, OrderParameter } from "@/models/OrderParameter.model";
import { useDefaultOrderStore } from "@/stores/DefaultOrder.store";
import { Classroom } from "@/models/Classroom.model";
import { useClassroomsStore } from "@/stores/Classrooms.store";
import { FilterDef } from '@/utils/FilterDefs.util';
import { DateTime } from 'luxon';

export class PupilFilterDef extends FilterDef {
	constructor() {
		super(['firstName', 'lastName', 'dateOfBirth']);
	}
}

interface PupilsStoreState {
	pupils: Pupil[],
	pupilsMap: { [id: number]: Pupil },
	symbolsInUseMap: { [url: string]: Pupil },
	iconsInUseMap: { [id: number]: Pupil },
	errorMessage: ErrorMessage | null,
	loaded: boolean,
	loadedClassroom: Classroom | null,	// null = all classrooms basically
	loadingPromise: Promise<void> | null,
	order: OrderParameter,
	filter: PupilFilterDef,
	filterTimeout: number | null,
}

export const usePupilsStore = defineStore('pupils', {

	state: (): PupilsStoreState => ({
		pupils: [],
		symbolsInUseMap: {},
		iconsInUseMap: {},
		errorMessage: null,
		loaded: false,
		loadedClassroom: undefined,
		loadingPromise: null,
		order: useDefaultOrderStore().pupilsOrder,
		filter: new PupilFilterDef(),
		filterTimeout: null,
		pupilsMap: {},
	}),

	getters: {

		isIconUsed: (state) => {
			return (icon: Icon | Symbol) => {
				if(icon instanceof Symbol && icon.url && typeof (state.symbolsInUseMap[icon.url]) !== 'undefined') {
					return true;
				} else if (icon instanceof Icon && icon.id && typeof (state.iconsInUseMap[icon.id]) !== 'undefined') {
					return true;
				} else {
					return false;
				}
			}
		},

		defaultMask: (state) => {
			return [
				'id',
				'color',
				'firstName',
				'lastName',
				'symbol',
				'birthdate',
				'classrooms.*',
				'classrooms.icon.*',
				'classrooms.icon.library.id',
			]
		}
	},

	actions: {

		clear() {
			this.pupils = [];
			this.pupilsMap = {};

			this.loaded = false;
			this.loadedClassroom = null;
		},

		async new(classroom: Classroom | null = null) {
			let newPupil = new Pupil();

			if (classroom !== null) {
				await newPupil.toggleClassroom(classroom, true);
			}

			return newPupil;
		},

		findById(id: number) {
			if (!this.pupilsMap[id]) {
				return null;
			}
			return this.pupilsMap[id];
		},

		findByIds(ids: string[]) {
			return this.pupils.filter(p => ids.indexOf(p.id) !== -1)
		},

		/**
		 * @param data
		 */
		findByIdOrMap(data: any) {

			// Find pupil in pupils store
			let pupil = this.findById(data.id);

			if (pupil) {
				return pupil;
			} else if (Object.keys(data).length > 1) {
				return Pupil.mapFromServer(data);
			} else if (data.id) {
				// Make pupil stub that only has an id
				let pupil = new Pupil();
				pupil.id = data.id;
				return pupil;
			}

			throw new Error('Invalid pupil data provided.');
		},

		async load(
			forceReload: boolean = false,
			awaitNewDataBeforeResettingList: boolean = false,
			classroom: Classroom | null = undefined
		) {
			this.filter.clear();	// currently this 'load' method will always load unfiltered data

			// If no classroom is provided (undefined), use the current classroom.
			if (typeof(classroom) === 'undefined') {
				classroom = useClassroomsStore().currentClassroom;
			}

			let shouldReload = false;
			let shouldResetBeforeReload = false;

			if (!this.loaded) {
				shouldReload = true;
				shouldResetBeforeReload = true;
			} else {
				shouldReload = forceReload ||
					(this.loadedClassroom && !classroom) ||
					(this.loadedClassroom?.id !== classroom?.id);

				shouldResetBeforeReload = shouldReload && !awaitNewDataBeforeResettingList;
			}

			const classroomId = classroom ? classroom.id : null;

			if (!shouldReload && this.loaded) {
				// already loaded (or loading)
				return this.loadingPromise;
			}

			// Reset the list if:
			// - classroom is different
			// - we request to do so (= default behaviour)
			if (shouldResetBeforeReload) {
				this.clear();
			}

			this.loadedClassroom = classroom || null;

			this.loadingPromise = new Promise<void>(async (resolve)  => {

				let response;
				try {
					response = await orgApi.get('pupils', {
						params: {
							classroomIds: classroomId,
							mask: this.defaultMask.join(',')
						}
					});
				} catch (e) {
					this.loadedClassroom = null;
					this.errorMessage = ApiErrors.fromAxiosException(e);
					throw this.errorMessage;
				}

				// Still loading for the same classroom?
				if (
					(!this.loadedClassroom && classroom) ||
					(this.loadedClassroom && this.loadedClassroom.id !== classroomId)
				) {
					this.loaded = true;
					return;
				}

				this.pupils = response.data.data.map(Pupil.mapFromServer);
				this.updateMap();

				// Sort records
				this.orderBy(this.order);

				if(classroom) {
					this.updateAvailableSymbolsIcons();
				}
				this.loaded = true;

				resolve();

			});

			return this.loadingPromise;
		},

		updateMap() {
			this.pupils.forEach(pupil => {
				this.pupilsMap[pupil.id] = pupil;
			});
		},

		async findByNameAndDateOfBirth(firstName: string, lastName: string, dateOfBirth: string) {

			let response;
			try {
				response = await orgApi.get('pupils', {
					params: {
						mask: this.defaultMask.join(','),
						firstName: firstName,
						lastName: lastName,
						birthdate: dateOfBirth
					}
				});

				return response.data.data.map(Pupil.mapFromServer);
			} catch (e) {
				this.loadedClassroom = null;
				this.errorMessage = ApiErrors.fromAxiosException(e);
				throw this.errorMessage;
			}

		},

		async save(pupil: Pupil) {

			this.errorMessage = null;

			if(pupil.id) {
				// update existing pupil
				try {
					const response = await orgApi.put('pupils/' + pupil.id, pupil.getServerData());
					this.updateStoreItem(pupil);
					this.orderBy();

				} catch (e: any) {
					this.errorMessage = ApiErrors.fromAxiosException(e);
					throw this.errorMessage;
				}

			} else {
				// create new pupil
				try {
					const response = await orgApi.post('pupils', pupil.getServerData());
					pupil.id = response.data.data.id;

					this.addStoreItem(pupil);
					this.orderBy();

				} catch (e: any) {
					this.errorMessage = ApiErrors.fromAxiosException(e);
					throw this.errorMessage;
				}
			}

			this.load(true, true, this.loadedClassroom);

		},

		async bulkSave(pupils: Pupil[]) {

			this.errorMessage = null;

			const data = pupils.map(p => p.getServerData());

			try {
				const response = await orgApi.post('pupils', data, {
					headers: {
						'X-Bulk': 'true',
					}
				});
				this.load(true, true, this.loadedClassroom);
			} catch (e: any) {
				this.errorMessage = ApiErrors.fromAxiosException(e);
				throw this.errorMessage;
			}

		},

		async delete(pupil: Pupil) {

			try {
				const response = await orgApi.delete('pupils/' + pupil.id);
				this.deleteStoreItem(pupil);

			} catch (e: any) {
				this.errorMessage = ApiErrors.fromAxiosException(e);
				throw this.errorMessage;
			}

		},

		clearErrorMessage() {
			this.errorMessage = null;
		},

		updateStoreItem(pupil: Pupil) {
			var storeIndex = this.pupils.findIndex(
				storeItem => storeItem.id == pupil.id
			);
			this.pupils[storeIndex] = pupil.clone();
			this.updateAvailableSymbolsIcons();
		},

		addStoreItem(pupil: Pupil) {
			this.pupils.push(pupil);
			this.updateAvailableSymbolsIcons();
		},

		deleteStoreItem(pupil: Pupil) {
			const index = this.pupils.findIndex(v => v.id === pupil.id);
			if (index >= 0) {
				this.pupils.splice(index, 1);
			}
			this.updateAvailableSymbolsIcons();
		},

		updateAvailableSymbolsIcons() {

			if(!this.loadedClassroom) {
				return;
			}

			this.symbolsInUseMap = {};
			this.iconsInUseMap = {};

			this.pupils.forEach(
				(pupil: Pupil) => {
					const icon = pupil.getIconForClassroom(this.loadedClassroom);
					if(icon instanceof Symbol) {
						this.symbolsInUseMap[icon.url] = pupil;
					} else if(icon instanceof Icon) {
						this.iconsInUseMap[icon.id] = pupil;
					}
				}
			);

		},

		changeOrder(orderBy: OrderParameter) {
			this.order = orderBy;
			useDefaultOrderStore().setDefaultPupilsOrder(orderBy);
			this.orderBy(orderBy);
		},

		orderBy(orderBy: OrderParameter = null) {
			if (!orderBy) {
				orderBy = this.order;
			}

			this.sortItemsBy(orderBy.property, orderBy.direction);
		},

		sortItemsBy(attr: string, direction = OrderDirection.ASC) {
			// Sort on name (for now)
			this.pupils.sort(
				(a: Pupil, b: Pupil) => {
					let result: number;
					if(!a[attr] && !b[attr]) {
						result = 0;
					} else if(!a[attr]) {
						result = 1;
					} else if(!b[attr]) {
						result = -1;
					} else {
						if(a[attr] instanceof DateTime) {
							result = a[attr].diff(b[attr]).milliseconds;
						} else {
							result = a[attr].localeCompare(b[attr]);
						}
					}

					if(direction === OrderDirection.DESC) {
						result = -result;
					}
					return result;
				}
			);
		},

		getCurrentFilter(): PupilFilterDef {
			return this.filter.clone();
		},

		async applyFilter(filter: PupilFilterDef, delay: number = 400) {

			if (this.filterTimeout) {
				clearTimeout(this.filterTimeout);
			}

			if(filter.isEmpty()) {
				this.load(true, false, this.loadedClassroom);
				return;
			}

			this.filterTimeout = setTimeout(async () => {
				this.pupils = await this.findByNameAndDateOfBirth(
					filter.getParamValues('firstName')[0],
					filter.getParamValues('lastName')[0],
					filter.getParamValues('dateOfBirth')[0]
				);

				this.orderBy();

				// cloning the passed filter object so the filter state of the store doesn't get changed directly by
				// updating the referenced filter object
				this.filter = filter.clone();
			}, delay);

		},

	},
});
