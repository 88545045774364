<template>

	<InsightsDrawer v-if="showDayInsights"
		:title="$t('Insights') + ' - ' + headerDate"
		sessionStateKey="insights.day"
		:defaultDateRange="dayAsInterval"
		:showPupilSelector="true"
		:showDateRangeSelector="false"
		:insights="[
						{ value: 'activity', label: this.$tc('Activity', 2) },
						{ value: 'feedback', label: this.$t('Feedback') },
					]"
		@requestClose="() => { this.showDayInsights = null; }"
	/>

	<div class="border-primary-focus border-b-4 py-1 sm:px-3 sm:gap-2 flex items-center justify-between">
		<div class="uppercase text-sm font-semibold whitespace-nowrap"
			 :class="[ isPast ? 'text-base-content-light' : '']">
			 <span class="hidden md:inline-block">{{ headerDay }}</span>&nbsp;
			 <span>{{ headerDate }}</span>
		</div>
		<div class="flex-grow-0">
			<!-- <button class="btn btn-ghost p-1 min-h-0 h-auto" @click="toggleCopyDayDialog">
					<DocumentDuplicateIcon class="w-4 h-4" />
			</button> -->
			<Dropdown>
				<template #trigger>
					<button type="button" class="btn btn-ghost p-1 min-h-0 h-auto">
						<EllipsisVerticalIcon class="w-5 h-5"/>
					</button>
				</template>
				<template #content>
					<DropdownMenu class="w-48">
						<DropdownMenuItem :disabled="!hasLessons">
							<a @click="showDayInsights = true">
								<ChartBarIcon class="w-4 h-4"/>
								{{ $t('Insights') }}
							</a>
						</DropdownMenuItem>
						<DropdownMenuItem :disabled="!hasLessons">
							<a @click="cloneDay">
								{{ $t('Copy to') }}...
							</a>
						</DropdownMenuItem>
						<DropdownMenuItem :disabled="!hasLessons">
							<a @click="clearDay">
								<TrashIcon class="w-4 h-4"/>
								{{ $t('Clear day schedule') }}
							</a>
						</DropdownMenuItem>
					</DropdownMenu>
				</template>
			</Dropdown>
		</div>
	</div>

	<Modal v-if="showCopyDayDialog" @requestUnmount="toggleCopyDayDialog">

		<template #header>
			<div class="font-semibold text-xl mb-4">{{ $t('Copy schedule of') }}
				&nbsp;<span class="inline-block border-b-2 border-primary">{{ dialogDate }}</span>
			</div>
		</template>

		<template #default="modalActions">

			<template v-if="lessonsStore.errorMessage?.genericError">
				<div class="alert alert-error mb-4 w-full sm:max-w-2xl m-auto">
					{{ $t(lessonsStore.errorMessage?.genericError.toString()) }}
				</div>
			</template>

			<div class="pl-2">{{ $t('to') }}...</div>

			<select class="select select-bordered w-full mb-4" v-model="selectedWeekToCopyTo"
					@change="updateWeekdaysToCopyTo">
				<option
					v-for="week in weeksToCopyTo"
					:key="week.key"
					:value="week.value"
					:selected="true"
				>
					{{ week.label }}
				</option>
			</select>

			<div class="pl-2">
				<span v-for="day in weekdaysToCopyTo" class="block mb-2" :class="{ 'opacity-30': !day.selectable }" :title="day.cantCopyReason">
					<input type="checkbox" name="weekday"
					   :value="day.value"
					   :id="'wd-' + day.value"
					   :disabled="!day.selectable"
					   v-model="selectedDays"
					/>
					<label class="ml-2" :for="'wd-' + day.value">
						{{ day.name }}
						<span>
							{{ day.date.toLocaleString({
								day: 'numeric',
								month: 'short'
							}) }}
						</span> <span v-if="day.isCopySource" class="text-xs">({{ $t('selected') }})</span>
					</label>
				</span>
			</div>

			<template v-if="requiresOverrideConfirmation">
				<div class="alert alert-warning mb-4 w-full sm:max-w-2xl m-auto">
					{{
						$t('Warning: (some) selected dates already contain a schedule. These schedules will be overwritten if you choose to continue.')
					}}
				</div>
			</template>

			<div class="modal-footer flex justify-end gap-1">
				<button class="btn btn-ghost" @click="modalActions.hide" :disabled="copyingDay">{{
						$t('Cancel')
					}}
				</button>

				<template v-if="requiresOverrideConfirmation">
					<button class="btn btn-primary" @click="copyToSelectedDays" :disabled="copyingDay">
						{{ $t('Overwrite') }}
					</button>
				</template>

				<template v-else>
					<button class="btn btn-primary" @click="copyToSelectedDays" :disabled="copyingDay">{{
							$t('Copy')
						}}
					</button>
				</template>

			</div>
		</template>

	</Modal>

	<Modal v-if="showClearDayConfirmation" @requestUnmount="closeClearDayConfirmation">
		<template #header>&nbsp;</template>

		<template #default="modalActions">
			{{ $t('Are you sure you want to delete the entire day\'s schedule?') }}

			<template v-if="lessonsStore.errorMessage?.genericError">
				<div class="alert alert-error mb-4 w-full sm:max-w-2xl m-auto">
					{{ $t(lessonsStore.errorMessage?.genericError.toString()) }}
				</div>
			</template>

			<div class="modal-footer flex justify-end gap-1">
				<button class="btn btn-ghost" @click="modalActions.hide">{{ $t('Cancel') }}</button>
				<button class="btn btn-danger" @click="confirmClearDay()" :class="[ clearingDay ? 'loading' : '' ]">
					{{ $t('Clear day schedule') }}
				</button>
			</div>
		</template>
	</Modal>

</template>


<script lang="ts">
import { DateTime, Interval } from "luxon";
import { DocumentDuplicateIcon, EllipsisVerticalIcon, TrashIcon, ChartBarIcon } from '@heroicons/vue/24/outline';
import Modal from './ui/Modal.vue';
import Dropdown from './ui/Dropdown.vue';
import DropdownMenu from "./ui/DropdownMenu.vue";
import DropdownMenuItem from "./ui/DropdownMenuItem.vue";
import { mapStores } from "pinia";
import { useLessonsStore } from "@/stores/Lessons.store";
import { ApiErrors } from "@/stores/errors/ApiErrors";
import config from "@/config/app.config";
import {useOrganisationsStore} from "@/stores/Organisations.store";

import InsightsDrawer from '@/components/InsightsDrawer.vue';

export default {
	name: 'AgendaDayHeader',
	props: {
		sourceDate: {
			type: DateTime,   // ISO format (yyyy-mm-dd)
			required: true
		}
	},
	components: {
		// SmsConfirmRemoveCard,
		DocumentDuplicateIcon, EllipsisVerticalIcon, TrashIcon, ChartBarIcon,
		Modal,
		Dropdown,
		DropdownMenu,
		DropdownMenuItem,
		InsightsDrawer,
	},

	watch: {
		sourceDate() {
			this.updateWeekdaysToCopyTo();
		}
	},

	data() {
		return {
			showCopyDayDialog: false,
			requiresOverrideConfirmation: false,
			selectedWeekToCopyTo: null,
			showClearDayConfirmation: false,
			clearingDay: false,
			copyingDay: false,
			selectedDays: [],
			weeksToCopyTo: [],
			weekdaysToCopyTo: [],
			showDayInsights: false,
		}
	},

	mounted() {
		// build 'weeks to copy to' list, as otherwise DateTime compare doesn't work with the select field.
		let lastMonday = DateTime.local().startOf('week');
		let weeks = [];

		for (let i = 0; i < 52; i++) {
			let week = lastMonday.plus({weeks: i});

			let label = this.$t('Wk') + ` ${week.weekNumber} : `;

			// if (week.year === this.sourceDate.year && week.weekNumber === this.sourceDate.weekNumber) {
			if (i === 0) {
				label += this.$t('current week');
			} else if (i === 1) {
				label += this.$t('next week');
			} else {
				label += week.toLocaleString({day: 'numeric', month: 'short'}) + ' - '
					+ week.plus({days: 6}).toLocaleString({day: 'numeric', month: 'short'});
			}

			weeks.push({
				label: label,
				key: week.weekNumber,
				value: week,
				fullWeekNumber: week.toFormat('kkkkWW')	// just for finding some specific week afterwards
			});
		}

		if(this.sourceDate < lastMonday) {
			// if source date is before current week, then default destination week is CURRENT week
			this.selectedWeekToCopyTo = weeks.find(w => w.fullWeekNumber === lastMonday.toFormat('kkkkWW')).value;
		} else {
			if(this.sourceDate.weekday === 5) {
				// if source date is a Friday, then default destination week is the week after the source date's week
				this.selectedWeekToCopyTo = weeks.find(w => w.fullWeekNumber === this.sourceDate.plus({weeks: 1}).toFormat('kkkkWW')).value;
			} else {
				// if source date is another weekday, then default destination week is the same week of the source date
				this.selectedWeekToCopyTo = weeks.find(w => w.fullWeekNumber === this.sourceDate.toFormat('kkkkWW')).value;
			}
		}
		// fallback
		if(this.selectedWeekToCopyTo === null) {
			this.selectedWeekToCopyTo = weeks[0].value;
		}

		this.weeksToCopyTo = weeks;

		// also set the weekdays to copy to
		this.updateWeekdaysToCopyTo();
	},

	computed: {
		...mapStores(useLessonsStore, useOrganisationsStore),

		headerDay() {
			return this.sourceDate.toLocaleString({ weekday: 'short' });
		},

		headerDate() {
			return this.sourceDate.toLocaleString({ day: 'numeric', month: 'numeric' })
		},

		dialogDate() {
			return this.sourceDate.toLocaleString({weekday: 'long', day: 'numeric', month: 'numeric'})
		},

		isPast() {
			return this.sourceDate.endOf('day') < DateTime.local().startOf('day');
		},

		hasLessons() {
			if (!this.sourceDate) {
				return false;
			}

			return this.lessonsStore.hasBetween(
				this.sourceDate.startOf('day').toJSDate(),
				this.sourceDate.endOf('day').toJSDate()
			);
		},

		dayAsInterval() {
			return Interval.fromDateTimes(this.sourceDate.startOf('day'), this.sourceDate.endOf('day'));
		},
	},

	methods: {
		// dateFormatting() {
		//     return this.sourceDate.toLocaleString({ weekday: 'short', day: 'numeric', month: 'numeric'})
		// },
		toggleCopyDayDialog(event) {
			// this.$refs.copyDayDialog.toggle(event)
			this.showCopyDayDialog = !this.showCopyDayDialog
			this.requiresOverrideConfirmation = false;
		},

		clearDay() {
			if (!this.hasLessons) {
				return;
			}

			this.lessonsStore.clearErrorMessage();
			this.showClearDayConfirmation = true;
		},

		cloneDay(day: DateTime) {
			if (!this.hasLessons) {
				return;
			}

			this.showCopyDayDialog = true;
			this.requiresOverrideConfirmation = false;
		},

		async confirmClearDay() {
			if (this.clearingDay) {
				return;
			}

			this.clearingDay = true;

			try {
				await this.lessonsStore.clearDay(this.sourceDate);
				this.closeClearDayConfirmation();
				this.clearingDay = false;
			} catch (e) {
				if (!(e instanceof ApiErrors)) {
					this.clearingDay = false;
					throw e;
				}
			}
		},

		closeClearDayConfirmation() {
			this.showClearDayConfirmation = false
		},

		updateWeekdaysToCopyTo() {

			this.selectedDays = [];

			this.weekdaysToCopyTo = [];

			let weekdays = 5;
			if (this.organisationsStore?.currentOrganisation.agenda_settings?.include_weekends) {
				weekdays = 7;
			}

			for (let i = 1; i <= weekdays; i++) {
				let startOfWeek = this.selectedWeekToCopyTo.startOf('week');
				if (startOfWeek === null) {
					startOfWeek = this.sourceDate.startOf('week');
				}

				let day = startOfWeek.plus({days: i - 1});

				let isCopySource = (day.weekNumber + '.' + day.weekday) === (this.sourceDate.weekNumber + '.' + this.sourceDate.weekday);
				let selectable = !isCopySource && day >= DateTime.local().startOf('day');

				let cantCopyReason = null;
				if (isCopySource) {
					cantCopyReason = this.$t('Cannot copy to same day');
				} else if (!selectable) {
					cantCopyReason = this.$t('Cannot copy to past day');
				}

				this.weekdaysToCopyTo.push({
					name: day.toLocaleString({weekday: 'long'}),
					date: day,
					value: day.weekday,
					selectable: selectable,
					isCopySource: isCopySource,
					cantCopyReason: cantCopyReason
				});

				if (selectable && day > this.sourceDate) {
					this.selectedDays.push(day.weekday);
				}
			}
		},

		async copyToSelectedDays() {

			this.copyingDay = true;

			let selectedDays = this.selectedDays;
			let sourceRange = Interval.fromDateTimes(
				this.sourceDate.startOf('day'),
				this.sourceDate.startOf('day').plus({days: 1})
			);

			let destinations: Interval[] = [];
			for (let i = 0; i < selectedDays.length; i++) {
				let day = this.selectedWeekToCopyTo.plus({days: selectedDays[i] - 1});
				let destinationRange = Interval.fromDateTimes(
					day.startOf('day'),
					day.startOf('day').plus({days: 1})
				);

				destinations.push(destinationRange);
			}

			try {
				await this.lessonsStore.copyTo(sourceRange, destinations, this.requiresOverrideConfirmation);
				this.showCopyDayDialog = false;
			} catch (e) {

				if (e.response?.status === 406) { // requires confirmation
					this.requiresOverrideConfirmation = true;
				} else if (e instanceof ApiErrors) {
					this.$notify({
						title: "Error!",
						text: e.toString(),
						type: "error"
					});
				} else {
					throw e;
				}
			} finally {
				this.copyingDay = false;
			}
		},
	}
}
</script>
