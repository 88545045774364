import {Organisation} from "./Organisation.model";
import { useUsersStore } from "@/stores/Users.store";

export class Classroom {

	public id ?: string;
	public name ?: string;
	public organisation ?: Organisation;
	public features : string[] = [];

	public pupilCount ?: number = 0;
	public teachers ?: string[] = [];
	public broadcast_channel ?: string;

	static mapFromServer(data: any) {
		const classroom = new Classroom();
		classroom.setFromServerData(data);

		return classroom;
	}

	hasId() {
		return !!this.id;
	}

	setFromServerData(data: any) {
		this.id = data.id;
		this.name = data.name;
		this.pupilCount = data.pupilCount;

		if (data.broadcast_channel) {
			this.broadcast_channel = data.broadcast_channel;
		}

		if (data.organisation) {
			this.organisation = Organisation.mapFromServer(data.organisation);
		}

		if (data.features) {
			this.features = data.features;
		}

		 if (data.teachers) {
			 this.teachers = data.teachers.map((teacher: any) => {
				 return teacher.id;
			 });
		 }
	}

	getTeachers() {
		return useUsersStore().fromIds(this.teachers);
	}

	getServerData() {
		return {
			id: this.id,
			name: this.name,
			teachers: this.teachers,
			broadcast_channel: this.broadcast_channel,
		}
	}

	clone(): Classroom {
		let clonedInstance = Object.assign(
			Object.create(
				Object.getPrototypeOf(this)
			),
			this
		);
		return clonedInstance;
	}
}
