<template>

	<Drawer :title="title" initialWidth="4xl" :closeOnClickOutside="true" @requestUnmount="hide">

		<template #default="{ hide }">

			<div class="p-6 pb-0 sticky z-10 top-0 bg-base">
				<InsightsFilterComponent
					v-model="filter"
					:showPupilSelector="showPupilSelector"
					:dateRangeSelectorReadOnly="currentReport === 'social'"
					:showDateRangeSelector="showDateRangeSelector"
					@change="applyFilter"
				/>

				<div class="border-b">
					<Tabs :value="currentReport" :options="insights" @change="changeReport" class="text-md"></Tabs>
				</div>
			</div>

			<div class="p-6">
				<template v-if="loaded">

					<template v-if="currentReport === 'activity'">
						<!-- the below pupil property should be obsolete once 'activity' report supports 'all pupils' query -->
						<InsightsActivities :pupil="pupil" :filter="filter"></InsightsActivities>
					</template>

					<template v-if="currentReport === 'feedback'">
						<InsightsFeedback :filter="filter" :withNameColumn="pupil ? false : true"></InsightsFeedback>
					</template>

					<!-- <template v-if="currentReport === 'social'">
						<InsightsSocial :filter="filter" />
					</template> -->

				</template>
			</div>

		</template>

	</Drawer>

</template>


<script lang="ts">
import { InsightsFilter } from "@/stores/Insights.store";
import InsightsActivities from "@/components/InsightsActivities.vue";
import InsightsFilterComponent from "@/components/InsightsFilter.vue";
import InsightsFeedback from "@/components/InsightsFeedback.vue";
import InsightsSocial from "@/components/InsightsSocial.vue";
import Drawer from '@/components/ui/Drawer.v2.vue';
import Tabs from "@/components/ui/Tabs.vue";

import { DateTime, Interval } from "luxon";


export default {
	emits: ['requestClose'],

	props: {
		title: {
			type: String,
			default: '',
		},
		pupil: {
			type: Object,
			default: null
		},
		defaultDateRange: {
			type: Interval,
			default: null
		},
		sessionStateKey: {
			type: String,
			required: true,
		},
		showPupilSelector: {
			type: Boolean,
			default: true
		},
		showDateRangeSelector: {
			type: Boolean,
			default: true
		},
		insights: {
			type: Object,
			required:true,
		},
	},

	components: {
		Drawer,
		Tabs,
		InsightsFeedback,
		InsightsFilterComponent,
		InsightsActivities,
		InsightsSocial,
	},

	data() {
		return {
			filter: null,
			loaded: false,
			currentReport: null,
		}
	},

	async mounted() {

		this.initializeFilter();
		this.currentReport = sessionStorage.getItem(this.sessionStateKey + ".report") ?? this.insights[0].value;
		this.loaded = true;

	},

	methods: {
		initializeFilter() {
			this.filter = new InsightsFilter();
			this.filter.pupilId = this.pupil ? this.pupil.id : null;
			this.filter.dateRange = this.retrieveDateRange();
		},

		hide () {
			this.$emit('requestClose');
		},

		applyFilter(filter: InsightsFilter) {
			this.filter = filter;
			this.storeDateRange(filter.dateRange);
		},

		changeReport(report: string) {
			if (report === 'social') {
				// last 31 days
				this.filter.dateRange = Interval.fromDateTimes(
					DateTime.now().endOf('day').minus({ days: 31 }),
					DateTime.now().endOf('day')
				);

			} else if (this.currentReport === 'social') {
				this.filter.dateRange = this.retrieveDateRange();
			}
			this.currentReport = report;
			sessionStorage.setItem(this.sessionStateKey + ".report", report);
		},

		storeDateRange(interval: Interval) {
			if(!this.sessionStateKey) {
				return;
			}
			sessionStorage.setItem(this.sessionStateKey + ".dateRange", JSON.stringify(interval.toISO()));
		},

		retrieveDateRange() {
			if(this.showDateRangeSelector && this.sessionStateKey !== null) {
				const intervalStr = sessionStorage.getItem(this.sessionStateKey + ".dateRange");

				if (intervalStr) {
					return Interval.fromISO(JSON.parse(intervalStr));
				}
			}
			if(this.defaultDateRange) {
				return this.defaultDateRange;
			} else {
				return Interval.fromDateTimes(DateTime.now().startOf('day'), DateTime.now().endOf('day'));
			}
		},
	}
}
</script>