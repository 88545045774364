<template>
	<TheBoardLayout>

		<LoadingScreen v-if="loading" />

		<Modal v-if="selectionError" :modalTitle="$t('Cannot open this board')" @requestUnmount="handleUnselectedLesson()" :closeOnClickOutside="true" ref="errorModal">

			<div v-if="selectionError === 'TOO_SOON'">
				<p>{{ $t('This lesson is scheduled for {scheduledStart}.', {
					scheduledStart: futureClassroomLessons.find(lesson => lesson.id === selectedLessonId)?.start.toLocaleString(undefined, { hour: 'numeric', minute: 'numeric' })
				}) }}</p>
				<p>{{ $t('You can open the activity board at the earliest {minutes} minutes before its scheduled start.', {
					minutes: this.minMinutesBeforeLessonStarts
				}) }}</p>
			</div>

			<div v-else-if="selectionError === 'NOTHING_TO_REFLECT_UPON'">
				<p v-text="$t('#notification: no data to reflect upon yet')"></p>
			</div>
		</Modal>

		<div class="h-screen flex-grow flex flex-col gap-4 justify-center items-center">

			<img src="/smartsymbols-logo.png" class="w-64 mb-8" alt="Smart Symbols logo" />

			<!-- No classroom selected yet -->
			<template v-if="!activeClassroom && initialized">
				<div v-if="activityBoardConnectStore.availableClassrooms.length" class="flex flex-col w-full max-w-md bg-base p-6 rounded-xl">
					<h2 class="py-2 text-xl">{{ $t('This device has been linked to following classrooms') }}:</h2>

					<div class="flex flex-col divide-y divide-base-300">
						<div v-for="token in activityBoardConnectStore.availableClassrooms" :key="token.classroom.id" class="py-2 flex justify-between items-center">
							<span class="flex-grow">{{ token.classroom.name }}</span>
							<button type="button" class="btn btn-accent" @click="setSelectedClassroom(token.classroom.id)">{{ $t("Choose") }}</button>
							<Dropdown position="top-end">
								<template #trigger>
									<button type="button" class="btn btn-circle btn-ghost bg-base-100 ml-4">
										<TrashIcon class="w-5 h-5"></TrashIcon>
									</button>
								</template>
								<template #content="dialogActions">
									<DropdownDialogConfirm
										confirm-label="Yes"
										cancel-label="No"
										confirm-style="danger"
										class="w-80"
										@cancel="dialogActions.close"
										@confirm="handleUnlinkClassroomConfirmation(token, dialogActions)">
										<span class="text-danger">{{ $t("Are you sure?") }}</span>
									</DropdownDialogConfirm>
								</template>
							</Dropdown>
						</div>
					</div>
				</div>
				<div class="p-6 flex flex-col gap-4 w-full max-w-md">
					<h2 class="text-xl">
						<span v-if="!activityBoardConnectStore.availableClassrooms.length">{{ $t("First, let's connect this device with a Smart Symbols classroom") }}:</span>
						<span v-else>{{ $t("Connect device with another Smart Symbols classroom") }}:</span>
					</h2>
					<p class="flex items-center gap-2">
						{{ $t("Classroom code") }}
						<Dropdown position="top-start">
							<template #trigger>
								<button><QuestionMarkCircleIcon class="w-5 h-5 inline-block"></QuestionMarkCircleIcon></button>
							</template>
							<template #content>
								<div class="bg-base rounded-lg shadow px-4 py-2 w-96 text-sm">
									{{ $t("#instruction: classroom code") }}
								</div>
							</template>
						</Dropdown>
					</p>

					<p v-if="this.connectError" class="text-danger">{{ this.connectError?.genericError }}</p>

					<form @submit.prevent="handleClassroomKeySubmission" class="flex items-center">

						<input type="text"
							v-model="enteredClassroomKey"
							placeholder="xxxxxx"
							class="input input-bordered text-center bg-base text-2xl"
						/>
						<button type="submit" class="btn btn-primary ml-4">
							{{ $t('Connect') }}
							<span v-show="loading" class="loading loading-spinner"></span>
						</button>
					</form>
				</div>
			</template>

			<!-- Classroom selected, but no lesson selected yet -->
			<template v-else-if="initialized">
				<div class="flex flex-col items-center gap-1">
					<h1 class="mb-8">
						<span>{{ $t("Classroom") }}</span>
						<div class="flex items-center gap-4">
							<span class="text-4xl">{{ activeClassroom.name }}</span>
							<button type="button" class="btn btn-sm btn-outline" @click="handleChangeClassroom">{{ $t("Change") }}</button>
						</div>
					</h1>
					<div v-if="!futureClassroomLessons.length" class="border bg-base-200 py-4 px-8 rounded-lg flex flex-col gap-4 items-center max-w-sm">
						<span class="italic">{{ $t("No upcoming activity boards found") }}</span>
						<span>{{ $t('To start an activity board, you first need to schedule a lesson with an activity board in the agenda.') }}</span>
					</div>
					<template v-for="(lesson, index) in futureClassroomLessons">
						<div v-if="lesson.id === selectedLessonId" class="px-8 py-4 bg-accent text-accent-content rounded-lg flex justify-between items-center" style="width: 28rem; animation: button-pop 0.25s ease-out;">
							<div class="flex flex-col">
								<span>{{ lesson.start.toLocaleString(undefined, { hour: 'numeric', minute: 'numeric' }) }} - {{ lesson.end.toLocaleString(undefined, { hour: 'numeric', minute: 'numeric' }) }}</span>
								<span class="text-2xl">{{ lesson.title ?? $t('Activity board') }}</span>
							</div>


							<button v-if="!canOpenActivityBoard(lesson, false)" type="button" class="btn btn-circle btn-lg btn-accent opacity-50" @click="handleStartLesson(lesson, false)">
								<PlayCircleIcon class="h-full w-full"></PlayCircleIcon>
							</button>

							<button v-if="canOpenActivityBoard(lesson, false)" type="button" class="btn btn-circle btn-lg btn-accent" @click="handleStartLesson(lesson, false)">
								<PlayCircleIcon class="h-full w-full"></PlayCircleIcon>
							</button>
						</div>
						<button v-else class="w-full px-8 py-4 max-w-sm border bg-white rounded-lg flex flex-row gap-4" @click="handleLessonSelection(lesson.id)">
							<span>{{ lesson.start.toLocaleString(undefined, { hour: 'numeric', minute: 'numeric' }) }} - {{ lesson.end.toLocaleString(undefined, { hour: 'numeric', minute: 'numeric' }) }}</span>
							<span>{{ lesson.title ?? $t('Activity board') }}</span>
						</button>
					</template>

					<div class="divider opacity-50"></div>

					<div type="button" v-if="selectedReflectionBoardId === 'today'" class="w-full px-8 py-4 bg-accent2 rounded-lg flex justify-between items-center text-accent2-content" style="width: 28rem; animation: button-pop 0.25s ease-out;">
						<div class="flex flex-col">
							<span class="text-2xl">{{ $t('Reflection board') }}</span>
							<span class="uppercase">{{ $t('Of today') }}</span>
						</div>
						<button :class="[!activityBoardConnectStore.hasReflectionBoardToday ? 'opacity-10 no-animation':'']" type="button" @click="openReflectionBoard('today')" class="btn btn-circle btn-lg bg-accent2 border-accent2 hover:bg-accent2-focus hover:border-accent2-focus text-accent2-content">
							<PlayCircleIcon class="h-full w-full"></PlayCircleIcon>
						</button>
					</div>
					<button type="button" v-else class="w-full px-8 py-4 max-w-sm border bg-white rounded-lg flex flex-row items-center gap-4" @click="handleReflectionBoardSelection('today')">
						<span class="text-xl">{{ $t('Reflection board') }}</span>
						<span class="">{{ $t('Of today') }}</span>
					</button>


					<template v-if="activityBoardConnectStore.hasReflectionBoardYesterday">
						<div type="button" v-if="selectedReflectionBoardId === 'yesterday'" class="w-full px-8 py-4 bg-accent2 rounded-lg flex justify-between items-center text-accent2-content" style="width: 28rem; animation: button-pop 0.25s ease-out;">
							<div class="flex flex-col">
								<span class="text-2xl">{{ $t('Reflection board') }}</span>
								<span class="uppercase">{{ $t('Of yesterday') }}</span>
							</div>
							<button :class="[!activityBoardConnectStore.hasReflectionBoardYesterday ? 'opacity-20':'']" type="button" @click="openReflectionBoard('yesterday')" class="btn btn-circle btn-lg bg-accent2 border-accent2 hover:bg-accent2-focus hover:border-accent2-focus text-accent2-content">
								<PlayCircleIcon class="h-full w-full"></PlayCircleIcon>
							</button>
						</div>
						<button type="button" v-else class="w-full px-8 py-4 max-w-sm border bg-white rounded-lg flex flex-row items-center gap-4" @click="handleReflectionBoardSelection('yesterday')">
							<span class="text-xl">{{ $t('Reflection board') }}</span>
							<span class="">{{ $t('Of yesterday') }}</span>
						</button>
					</template>


				</div>
			</template>
		</div>

	</TheBoardLayout>
</template>



<script lang="ts">
import { mapState, mapStores } from "pinia";
import {MAX_LESSON_START_PREPONING, useActivityBoardStore} from '@/stores/ActivityBoard.store';
import { useClassroomsStore } from '@/stores/Classrooms.store';

import TheBoardLayout from './layout/TheBoardLayout.vue';
import LoadingScreen from './ui/LoadingScreen.vue';
import Dropdown from './ui/Dropdown.vue';
import DropdownDialogConfirm from './ui/DropdownDialogConfirm.vue';
import { TrashIcon, QuestionMarkCircleIcon, PlayCircleIcon } from '@heroicons/vue/24/outline';
import { useActivityBoardConnectStore } from "@/stores/ActivityBoardConnect.store";
import { DateTime } from "luxon";
import { useLessonsStore } from "@/stores/Lessons.store";
import {Lesson} from "@/models/Lesson.model";
import Modal from "@/components/ui/Modal.vue";
import {watch} from "vue";

export default {
	components: {
		Modal,
		TheBoardLayout,
		LoadingScreen,
		Dropdown,
		DropdownDialogConfirm,
		TrashIcon, QuestionMarkCircleIcon, PlayCircleIcon,
	},

	data() {
		return {
			enteredClassroomKey: '',
			cachedClassrooms: [],
			selectedLessonId: null,
			loading: false,
			initialized: false,
			connectError: null,
			selectedLessonWatcher: null,
			selectionError: null,
			selectedReflectionBoardId: null,
		}
	},

	computed: {
		...mapStores(useActivityBoardStore, useClassroomsStore, useActivityBoardConnectStore, useLessonsStore),
		...mapState(useActivityBoardConnectStore, ['classroomLessons', 'futureClassroomLessons']),

		activeClassroom() {
			return this.classroomsStore.currentClassroom;
		},

		minMinutesBeforeLessonStarts() {
			return Math.floor(MAX_LESSON_START_PREPONING / 60);
		},
	},

	async created() {

		await this.activityBoardConnectStore.loadFromLocalStorage();

		let classroomId = window.localStorage.getItem('activity_board_classroom');
		if (this.$route.query.code) {
			await this.submitClassroomKey(this.$route.query.code, false);
			this.$router.replace({ name: 'activity-board-select' });
		} else if (classroomId) {
			try {
				await this.setSelectedClassroom(classroomId);

				// do we have an lesson as well?
				let lessonId = window.localStorage.getItem('activity_board_lesson');
				if (lessonId) {

					if (lessonId.substring(0, 'reflection-board'.length) === 'reflection-board') {
						switch (lessonId) {
							case 'reflection-board-today':
							case 'reflection-board-yesterday':
								this.$router.push({ name: lessonId });
								break;

							default:
								console.error('Unknown reflection board id: ' + lessonId);
						}
						return;
					}

					let lesson = this.classroomLessons.find(lesson => lesson.id === lessonId);
					if (lesson) {
						await this.handleStartLesson(lesson, true);
					}
				}

			} catch (e) {
				console.error(e);
			}
		}

		this.initialized = true;
	},


	methods: {
		async handleClassroomKeySubmission() {
			await this.submitClassroomKey(this.enteredClassroomKey, true);
		},

		async submitClassroomKey(key: string, showError: false) {

			this.loading = true;
			this.connectError = null;

			try {
				let classroomToken = await this.activityBoardConnectStore.connect(key);
				if (!classroomToken) {
					this.loading = false;
					return;
				}

				this.enteredClassroomKey = '';

				await this.setSelectedClassroom(classroomToken.classroom.id);
			} catch (e) {
				console.error(e);

				if (showError) {
					this.connectError = e;
				}
			}

			this.loading = false;
		},

		handleUnlinkClassroomConfirmation(token, dialogActions) {
			this.activityBoardConnectStore.removeToken(token);
			dialogActions.close();
		},

		async setSelectedClassroom(classroomId) {

			try {
				this.loading = true;

				if (!await this.activityBoardConnectStore.selectClassroomId(classroomId)) {
					// that did not work.
					this.loading = false;
					return;
				}

				window.localStorage.setItem('activity_board_classroom', classroomId);

				await this.activityBoardConnectStore.loadClassroomLessons();

				if (this.classroomLessons.length > 0) {
					this.selectedLessonId = this.classroomLessons[0].id;
				}
				this.loading = false;

			} catch (e) {
				console.error(e);
				this.loading = false;
			}

		},

		handleChangeClassroom() {
			/*
			this.activeClassroom = null;
			localStorage.removeItem('activityBoardLatestActiveClassroom');*/

			this.activityBoardConnectStore.deselectToken();
		},

		async handleStartLesson(lesson: Lesson, autoLoad: boolean) {

			this.resetSelectedLessonWatcher();

			// Check if the lesson is in the future
			if (!this.canOpenActivityBoard(lesson, !autoLoad)) {

				window.localStorage.removeItem('activity_board_lesson')

				// Listen for any change to the lesson (very probably caused by an incoming pusher event)
				// and automatically open the lesson if the time is right this time.
				// This is to allow teachers to quickly run to their laptops, drag the lesson to a slightly
				// earlier time and have the board open automatically.
				// Note that we need to stop listening to the lesson once it has been opened!
				this.selectedLessonWatcher = watch(lesson, (newVal, oldVal) => {
					this.handleStartLesson(lesson, true);
				});

				return;
			}

			window.localStorage.setItem('activity_board_lesson', lesson.id);

			this.loading = true;
			await this.activityBoardStore.load(lesson, !autoLoad);

			if (lesson.boardType === 'activities') {
				this.$router.push({ name: 'activity-board' });
			} else {
				alert('Unknown lesson type: ' + lesson.boardType);
			}

		},

		/**
		 * When a lesson is selected that is not valid for opening, we start watching for changes to the lesson
		 * This method removes that watcher.
		 */
		resetSelectedLessonWatcher() {
			if (this.selectedLessonWatcher) {
				this.selectedLessonWatcher();
				this.selectedLessonWatcher = null;
			}
		},

		handleUnselectedLesson() {
			this.selectionError = null;
			this.resetSelectedLessonWatcher();
		},

		openReflectionBoard(day: string) {

			switch (day) {
				case 'today':
				default:
					if(!this.activityBoardConnectStore.hasReflectionBoardToday) {
						this.selectionError = 'NOTHING_TO_REFLECT_UPON';
						return;
					}

					window.localStorage.setItem('activity_board_lesson', 'reflection-board-today');
					this.$router.push({ name: 'reflection-board-today' });
					break;

				case 'yesterday':
					if(!this.activityBoardConnectStore.hasReflectionBoardYesterday) {
						this.selectionError = 'NOTHING_TO_REFLECT_UPON';
						return;
					}

					window.localStorage.setItem('activity_board_lesson', 'reflection-board-yesterday');
					this.$router.push({ name: 'reflection-board-yesterday' });
					break;
			}
		},

		canOpenActivityBoard(lesson: Lesson, setError: boolean) {

			// Is the lesson more than 5 minutes in the future?
			if (
				DateTime.fromJSDate(lesson.start) >
				DateTime.now().plus({ second: MAX_LESSON_START_PREPONING })) {
				if (setError) {
					this.selectionError = 'TOO_SOON';
				}
				return false;
			}

			return true;

		},

		handleLessonSelection(lessonId: string) {
			this.selectedLessonId = lessonId;
			this.selectedReflectionBoardId = null;
		},

		handleReflectionBoardSelection(rbid: string) {
			this.selectedLessonId = null;
			this.selectedReflectionBoardId = rbid;
		}
	}
}
</script>
