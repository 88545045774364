<template>
	<div :class="wrapperClasses">
		<div ref="triggerwrapper">
			<slot name="trigger"></slot>
		</div>

		<Transition
            enter-from-class="transform opacity-0 scale-95"
            enter-active-class="transition ease-out duration-100"
            enter-to-class="transform opacity-100 scale-100"
            leave-from-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-to-class="transform opacity-0 scale-95"
        >
			<div v-show="show" v-bind="$attrs" :id="id" @blur="handleOnBlur" ref="dropdowncontent" tabindex="-1" class="absolute z-50 focus:outline-none" :class="positioningClasses">
				<slot name="content" :open="open" :close="close"></slot>
			</div>
		</Transition>

    </div>
</template>


<script lang="ts">
// Rather simple popover implementation based on focus/focus-out https://stackoverflow.com/a/53956904
export default {
	inheritAttrs: false,
	emits: [
		'open',
		'close',
	],
	props: {
		position: {
			type: String,
			default: 'bottom-end',
			validator(value) {
                return ['bottom-end', 'bottom-start', 'top-end', 'top-start'].includes(value);
            },
		},
		wrapperClasses: {
			type: String,
			default: 'relative text-left',
		},
	},
	data() {
		return {
			show: false,
			id: 'dd-' + Math.floor(Math.random() * 999999),
		};
	},
	mounted() {
		const triggerEl = this.$refs.triggerwrapper.firstElementChild;
		if(triggerEl) {
			triggerEl.addEventListener('click', this.open);
		}
	},
	computed: {
		positioningClasses() {
			let classes = '';
			switch(this.position) {
				case 'bottom-start':
					classes = 'left-0 mt-2 origin-top-left';
					break;
				case 'top-start':
					classes = 'left-0 bottom-full mb-2 origin-bottom-left';
					break;
				case 'top-end':
					classes = 'right-0 bottom-full mb-2 origin-bottom-right';
					break;
				default:
					classes = 'right-0 mt-2 origin-top-right';
					break;
			}
			return classes;
		},
	},
	methods: {
		open() {
			this.show = true;
			this.$emit('open');
			setTimeout(() => this.$refs.dropdowncontent.focus(), 100);	// add short timeout to allow popover to be rendered properly
		},
		close() {
			this.show = false;
			this.$emit('close');
		},
		handleOnBlur(event) {
			if(!event.relatedTarget || !event.relatedTarget.closest('#' + this.id)) {	// only close when focus is lost to outside element
				this.close();
			} else if(event.relatedTarget.tagName !== 'INPUT') {
				event.target.focus();	// set focus to dropdown again so next outside click triggers blur event again
			}
		},
	}
}
</script>
