<template>
<TheStdLayout :title="$tc('Pupil', 2)">

	<PupilEditor
		v-if="selectedPupilForEditing"
		:pupil="selectedPupilForEditing"
		@requestClose="hideEditForm"
		:scope="scope"
	/>

	<PupilImporter
		v-if="showImporter"
		@requestClose="hideImporter"
		:classroom="pupilImportClassroom"
	/>

	<InsightsDrawer v-if="insightsSelectedPupil"
		:title="$t('Insights') + ' - ' + insightsSelectedPupil.firstName + ' ' + insightsSelectedPupil.lastName"
		sessionStateKey="insights.pupil"
		:pupil="insightsSelectedPupil"
		:showPupilSelector="false"
		:defaultDateRange="dateRangeCurrentWeek"
		:showDateRangeSelector="true"
		:insights="[
						{ value: 'activity', label: this.$tc('Activity', 2) },
						{ value: 'feedback', label: this.$t('Feedback') },
						//{ value: 'social', label: this.$t('Social interaction') },
					]"
		@requestClose="() => { this.insightsSelectedPupil = null; }"
	/>

	<div class="px-4 py-6 mx-auto sm:px-6 md:px-8">

		<Dropdown class="top-16" wrapperClasses="sticky top-2">
			<template #trigger>
				<FloatingPlusButton />
			</template>
			<template #content>
				<DropdownMenu class="w-48">
					<DropdownMenuItem>
						<a @click="handlePupilCreateRequest">
							<UserPlusIcon class="w-5 h-5 mr-2" />
							{{ $t('Add pupil') }}
						</a>
					</DropdownMenuItem>
					<DropdownMenuItem>
						<a @click="handleImportRequest">
							<UsersIcon class="w-5 h-5 mr-2" />
							{{ $t('Import') }}
						</a>
					</DropdownMenuItem>
				</DropdownMenu>
			</template>
		</Dropdown>

		<EmptyState v-if="pupilsStore.loaded && pupilsStore.pupils.length === 0 && pupilsStore.getCurrentFilter().isEmpty()"
			:title="$t('No pupils yet')"
			:description="$t('Start with creating your first pupil')"
			:cta-label="$t('New pupil')"
			:cta-callback="handlePupilCreateRequest"
		/>

		<div v-else>

			<div v-if="showSearch" class="mb-10">
				<PupilFilter @change="pupilsStore.applyFilter" />
			</div>

			<table class="divide-y divide-base-200 w-full">

				<thead>
					<tr>
						<th v-if="showSymbols"><span class="sr-only">{{$t('Symbol')}}</span></th>
						<th scope="col" class="table-col-title">
							<SortableHeader property="firstName" :order="pupilsStore.order" @change="pupilsStore.changeOrder">{{ $t('First name') }}</SortableHeader>
						</th>

						<th scope="col" class="table-col-title">
							<SortableHeader property="lastName" :order="pupilsStore.order" @change="pupilsStore.changeOrder">{{ $t('Name') }}</SortableHeader>
						</th>

						<th scope="col" class="table-col-title">
							<SortableHeader property="birthdate" :order="pupilsStore.order" @change="pupilsStore.changeOrder">{{ $t('Date of birth') }}</SortableHeader>
						</th>

						<th scope="col" class="px-6 py-3">
							<span class="sr-only">{{ $t('Actions') }}</span>
						</th>
					</tr>
				</thead>

				<tbody class="bg-base divide-y divide-base-200">

					<tr v-show="!pupilsStore.loaded">
						<td colspan="5">
							<Skeleton content-type="userlist" :items="3" class="m-4" thumbnail-classes="mask mask-squircle" />
						</td>
					</tr>

					<tr v-show="!pupilsStore.getCurrentFilter().isEmpty() && pupilsStore.pupils.length === 0">
						<td colspan="5">
							<div class="px-4 py-6 italic">
								{{ $tc('Results', 0) }}
							</div>
						</td>
					</tr>

					<tr v-for="(pupil, key) in pupilsStore.pupils" :key="key">
						<td class="px-4 py-3 w-12 print:py-1" v-if="showSymbols">
							<pupil-symbol :pupil="pupil" class="w-14" />
						</td>
						<td class="px-4 py-3 whitespace-nowrap print:py-1">
							{{ pupil.firstName }}
						</td>
						<td class="px-4 py-3 whitespace-nowrap print:py-1">
							{{ pupil.lastName }}
						</td>

						<td class="px-4 py-3 whitespace-nowrap print:py-1">
							{{ formatBirthdate(pupil.birthdate) }}
						</td>

						<td class="px-4 py-3 whitespace-nowrap text-right text-sm font-medium flex justify-end gap-2 sm:gap-4 print:py-1">
							<div class="flex print:hidden gap-2">
								<button class="btn btn-ghost btn-circle bg-base-100" @click="handlePupilEditRequest(pupil)">
									<PencilIcon class="w-5 h-5"></PencilIcon>
								</button>
								<button v-if="observationRegistrationsEnabled" class="btn btn-ghost btn-circle bg-base-100" @click="createObservation(pupil)">
									<svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 512 512" stroke="currentColor">
										<path d="M448 0H64C28.7 0 0 28.7 0 64v288c0 35.3 28.7 64 64 64h96v84c0 7.1 5.8 12 12 12 2.4 0 4.9-.7 7.1-2.4L304 416h144c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64zm32 352c0 17.6-14.4 32-32 32H293.3l-8.5 6.4L192 460v-76H64c-17.6 0-32-14.4-32-32V64c0-17.6 14.4-32 32-32h384c17.6 0 32 14.4 32 32v288zM344 192h-72v-72c0-4.4-3.6-8-8-8h-16c-4.4 0-8 3.6-8 8v72h-72c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h72v72c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8v-72h72c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8z" />
									</svg>
								</button>
								<button v-if="showStatistics" class="btn btn-ghost btn-circle bg-base-100" @click="insightsSelectedPupil = pupil">
									<ChartBarIcon class="w-5 h-5"></ChartBarIcon>
								</button>
							</div>
						</td>

					</tr>

				</tbody>
			</table>
		</div>
  	</div>

	<ObservationEditor v-if="selectedPupilForObservation" :pupil="selectedPupilForObservation" @requestClose="closeObservationPopup" />

</TheStdLayout>
</template>

<script lang="ts">

import Skeleton from '@/components/ui/Skeleton.vue';
import PupilSymbol from "@/components/ui/symbols/PupilSymbol.component.vue";

import { mapState, mapStores } from "pinia";
import {usePupilsStore} from "@/stores/Pupils.store";
import ObservationEditor from "@/components/ObservationEditor.vue";
import PupilEditor from '@/components/PupilEditor.vue';
import PupilFilter from '@/components/PupilFilter.vue';

import { PlusIcon, PencilIcon, UserPlusIcon, UsersIcon, SquaresPlusIcon, ChartBarIcon } from '@heroicons/vue/24/outline';
import SortableHeader from "@/components/ui/SortableHeader.vue";
import { useDefaultOrderStore } from "@/stores/DefaultOrder.store";
import {DateTime, Interval} from "luxon";
import {useClassroomsStore} from "@/stores/Classrooms.store";
import PupilImporter from "@/components/PupilImporter.vue";
import {Pupil} from "@/models/Pupil.model";
import Dropdown from '@/components/ui/Dropdown.vue';
import DropdownMenu from '@/components/ui/DropdownMenu.vue';
import DropdownMenuItem from '@/components/ui/DropdownMenuItem.vue';

import InsightsDrawer from '@/components/InsightsDrawer.vue';

export default {

	components: {
		PupilImporter,
		SortableHeader,
		Skeleton,
		PupilSymbol,
		PupilEditor,
		PupilFilter,
		ObservationEditor,
		ChartBarIcon,
		InsightsDrawer,
		PlusIcon, PencilIcon, UserPlusIcon, UsersIcon,
		Dropdown, DropdownMenu, DropdownMenuItem,
	},

	data() {
		return {
			selectedPupilForEditing: null,
			selectedPupilForObservation: null,
			drawerFormVisible: false,
			showImporter: false,
			insightsSelectedPupil: null,
		};
	},

	props: {
		scope: {
			type: String,
			default: 'classroom',
		},
	},

	watch: {
		scope() {
			this.loadPupils();
		},
	},

	computed: {
		...mapStores(usePupilsStore, useDefaultOrderStore, useClassroomsStore),

		dateRangeCurrentWeek() {
			return Interval.fromDateTimes(DateTime.now().startOf('week'), DateTime.now().endOf('week'));
		},

		showSymbols() {
			return this.scope === 'classroom';
		},

		showSearch() {
			return this.scope === 'organisation';
		},

		showStatistics() {
			return this.scope === 'classroom';
		},

		observationRegistrationsEnabled() {
			return this.scope === 'classroom';
		},

		formatBirthdate() {
			return (birthdate: DateTime | null) => {
				if (!birthdate) {
					return '';
				}
				return birthdate.toFormat('dd/MM/yyyy');
			};
		},

		pupilImportClassroom() {
			return this.scope === 'classroom' ? this.classroomsStore.currentClassroom : null;
		},
	},

	methods: {

		async handlePupilCreateRequest() {
			this.selectedPupilForEditing = await this.pupilsStore.new(
				this.scope === 'classroom' ? this.classroomsStore.currentClassroom : null
			);
		},

		handlePupilEditRequest(pupil: Pupil) {
			this.selectedPupilForEditing = pupil;
		},

		async loadPupils() {
			await this.pupilsStore.load(
				false,
				false,
				this.scope === 'classroom' ? this.classroomsStore.currentClassroom : null
			);
		},

		closeEditPopup() {
			this.selectedPupilForEditing = null;
		},

		closeObservationPopup() {
			this.selectedPupilForObservation = null;
		},

		createObservation(pupil) {
			this.selectedPupilForObservation = pupil;
		},

		hideEditForm() {
			this.selectedPupilForEditing = null;
		},

		handleImportRequest() {
			this.showImporter = true;
		},

		hideImporter() {
			this.showImporter = false;
		},
	},

	async mounted() {
		await this.loadPupils();
	}
};

</script>
